@import "../variables";
@import "../mixins";

@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/slider/common";
@import "~@blueprintjs/core/src/components/slider/slider";

.#{$ns}-slider {
	box-sizing: border-box; padding: 0; width: auto; min-width: 0; margin: $gridunit $gridunit 0 $gridunit; font-size: 10px; height: 60px;

	&-track { height: 4px; top: 6px; background-color: $color-gray-lightest; }
	&-progress {
		top: 0; bottom: 0;
		&.#{$ns}-intent-primary { background-color: $color-gray-medium; }
	}
	&-label { font-size: inherit; }

	&-handle {
		width: 16px !important; height: 16px !important; background: $color-link; border-radius: 50% !important; outline:  none !important; margin: 0 !important; box-shadow: none;
		&:hover,
		&.#{$ns}-active { background: $color-link-hover; box-shadow: none; }
	}
	&-handle &-label { background: $color-white; box-shadow: 0 0 6px -3px rgba($color-black, 0.3); padding: 2px 6px; border-radius: $base-radius; color: $color-gray-dark; line-height: 16px; }
}
