.collapsible {
    &-group {}
    &-panel {
        &__heading {
            border-bottom: 1px solid $brand-gray-350; padding: $gridunit $gridunit*2 $gridunit $gridunit*3; position: relative; cursor: pointer;
            H3 { margin: 0; text-transform: uppercase; font-size: $base-font-size; font-weight: $bold; }
            &:before { content: ""; width:  $gridunit-small*1.5; height:  $gridunit-small*1.5; box-sizing: border-box; position: absolute; left: $gridunit; top: $gridunit-small*2; transform: rotate(135deg); border: 3px $color-text-dark; border-style: solid solid none none; }
            &:hover,
            &:active {
                background: red;
                &:before { transform: rotate(315deg); top: 20px; }
            }
            &:focus { box-shadow: $shadow-focus; }
            &[aria-expanded="true"] {
                &:before { transform: rotate(315deg); top: 20px; }
                &:hover,
                &:active {
                    background: red;
                    &:before { transform: rotate(135deg); top: $gridunit-small*2; }
                }
            }
        }
        &__content {
            line-height: 1.5; padding: $gridunit $gridunit $gridunit $gridunit*3; border-bottom: 1px solid $brand-gray-350;
            > *:first-child { margin: 0; }
            &[aria-hidden="true"] { display: none; }
        }
    }
}
