.frame-epharma {
  &__header {
    .logo {
      IMG { width: 280px; height: 60px; object-position: left; object-fit: contain; }
      @include mobile { max-width: 180px; }
    }
    &__nav {
      @include not-mobile {
        box-shadow: $shadow03;
      }
    }
    @include mobile {
      &,
      .is-sticky & {
        min-height: 0;
      }
    }
  }
  &__footer {
    &__primary { background-color: #4F4F50; }
    .text-intent-danger { color: #FFB3AD; }
  }
}


.site-menu {
  NAV {
    > UL {
      .badge {
        svg path { stroke: $brand-campaign-1; }
      }
    }
  }
}

.illustration-idcard { display: none; }

.shortcuts {
  background-color: transparent;
  &__item-icon {
    background-color: $brand-primary-800;
  }
}

.frame-epharma__pre-header__contacts LI { color: $color-black; }

.cart-products__item {
  .box-product {
    @include not-mobile {
      .box-product__total {
        flex: 1 0 auto;
        P { justify-content: flex-end; }
      }
      .box-product__actions-group {
        &:last-child {
          flex: 1 0 auto; justify-content: flex-end;

          &:first-child { justify-content: flex-start; }
          .box-product__total { flex: 1 0 auto; }
        }
      }
    }
  }
  &.tablet-compact {
    @include tablet {
      .box-product {
        .box-product__actions-group {
          &:last-child:first-child { justify-content: flex-start; padding-left: 100px; }
        }
      }
    }
  }
}
