.page-store {
  /*max-width: 1440px; margin: 0 auto;*/ font-size: 20px; line-height: 1.4; color: $color-text-dark;
  &-content {
    margin-top: $gridunit-large*3;
    > P,
    > H2,
    > H3,
    > H4,
    > H5,
    > H6 { padding: 0 $gridunit; }
    P { margin: 1em 0; }
  }
  &-header {
    position: relative; height: 400px;
    &__inner { position: absolute; left: 0; right: 0; bottom: 0; max-width: $content-maxwidth; margin: 0 auto; }
    &-content {
      max-width: 600px; position: relative; top: $gridunit; background: $color-white; flex: 0 0 50%; padding: $gridunit; box-sizing: border-box; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius;
    }
    H1 { margin: 0; font-weight: $bold; }
    IMG { position: absolute; left: 50%; top: 0; transform: translateX(-50%); max-width: none; }
    &-contacts {
      font-size: 16px; display: flex; flex-wrap: wrap; list-style: none; padding: 0; margin: 0 $gridunit*-1;
      SPAN {
        display: block;
        &.b { font-weight: 700; }
      }
      > LI {
        margin: $gridunit 0 0; padding: 0 $gridunit; position: relative; border-right: 1px solid $brand-gray-300;
        &:last-child { border-right: none; }
      }
    }
    @include mobile {
      height: auto; padding-top: 200px;
      IMG { height: 200px; }
      &__inner { position: static; background: transparentize($brand-primary-800-wcag, 0.9) }

    }
  }
  &-services {
    display: flex; flex-wrap: wrap; justify-content: center; list-style: none; padding: 0; margin: 0 $gridunit*-1;
    > LI {
      flex: 0 0 33.333%; box-sizing: border-box; padding: $gridunit*2 $gridunit 0; display: flex;
      .img {
        flex: 0 0 80px; margin-right: $gridunit; text-align: center;
        H3 { margin: 0; font-size: 20px; }
      }
      .text { flex: 0 1 100%; }
      UL {
        list-style: none; padding: 0; margin: 0; font-size: 16px;
        LI { margin-bottom: 0; }
      }
    }
    &-icons {
      list-style: none; display: flex; flex-wrap: wrap; padding: 0; margin: 0;
      > LI { flex: 0 0 auto; margin: 0 $gridunit $gridunit 0; text-align: center; font-size: 20px; font-weight: 700; }
      IMG { display: block; margin: 0 auto; }
    }
    &-list {
      list-style: none; padding: 0; margin: $gridunit 0 0;
      > LI {
        position: relative; padding-left: 20px; margin: $gridunit/2 0 0;
        &:before { content: ""; background: $brand-accent-wcag; width: 8px; height: 8px; position: absolute; left: 0; top: 10px; border-radius: 50%; }
      }
    }
    @include tablet {
      > LI { flex: 0 0 50%; }
    }
    @include mobile {
      margin: 0;
      > LI { flex: 0 0 100%; padding: $gridunit 0 0; }
    }
  }
  &-persons {
    display: flex; flex-wrap: wrap; justify-content: flex-start; list-style: none; padding: 0; margin: $gridunit-large*2 $gridunit*-1 0;
    &-item {
      flex: 0 0 50%; box-sizing: border-box; padding: $gridunit/2; margin: 0; display: flex; flex-direction: column;
      &__inner {
        background: $color-white; display: flex; box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
        .page-store-overlay & {
          box-shadow: none;
          .text {
            justify-content: flex-start; font-size: 20px;
            H3 { font-size: 28px; }
            H4 { font-size: 22px; }
          }
        }
        .img {
          flex: 0 0 50%; text-align: center;
          IMG { display: block; max-width: 100%; height: auto; }
        }
        .text {
          flex: 0 0 50%; font-size: 16px; padding: 25px; box-sizing: border-box; display: flex; flex-direction: column; justify-content: center;
          H3, H4 { margin: 0; }
          A { font-weight: 700; }
          P.meta { margin: 0; }
        }
      }
      &__overlay { display: none; }
    }
    &-languages {
      list-style: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap;
      LI { margin: $gridunit/2 $gridunit/2 0 0; }
    }
    @include mobile {
      &-item {
        flex: 0 0 100%; margin: $gridunit 0 0;
        &__inner {
          flex-direction: column;
          .text, .img { flex: 0 0 auto; }
          .img { padding: $gridunit; }
        }
      }
    }
  }
  &-notification {
    padding: $gridunit; margin: $gridunit auto 0; max-width: 1190px; box-sizing: border-box; text-align: center;
    > *:first-child { margin: 0; }
    &__theme01 { background: $color-system-warn-200; }
    &__theme02 { background: $color-system-info-200; }
    &__theme03 { background: $color-system-success-200; }
  }
  &-columns {
    margin: $gridunit-large*2 $gridunit*-1 0;
    @include not-mobile {
      display: flex;
    }
  }
  &-column { flex: 1; padding: 0 $gridunit; }
  P.center { max-width: 1000px; margin: $gridunit*2 auto; }
  P.ico-location {
    position: relative; padding-left: 40px; margin: 1.5em 0 0 0;
    &:before { position: absolute; left: 5px; top: -3px; color: $brand-accent-1; font-size: 32px; }
  }
  H2 { font-size: 34px; font-weight: 400; }

  &-section {
    margin: $gridunit auto 0; max-width: $content-maxwidth; padding: 0 $gridunit; box-sizing: border-box;
    &__inner { margin: 0 auto; max-width: $content-maxwidth; }
    &__full { max-width: 100%; }

    &__theme01 {
      background: transparentize($brand-primary-800-wcag, 0.95); padding: $gridunit-large*2 $gridunit;
      @include mobile {
        padding: $gridunit;
      }
    }
  }
  &-overlay {
    position: fixed; z-index: 900; left: 0; top: 0; bottom: 0; right: 0; display: none; justify-content: center; align-items: center; background: transparentize($brand-primary-800-wcag,0.2);
    &__open { display: flex; }
    &-content { border-radius: $base-radius; line-height: 1.4; max-width: 1000px; position: relative; padding: $gridunit; max-height: 90vh; overflow: auto; background: $color-white; box-sizing: border-box; }
    &-close { position: absolute; right: $gridunit; top: $gridunit; font-size: 16px; color: $brand-accent-wcag !important; text-decoration: underline; }
    @include mobile {
      &-content { max-height: 100vh; }
      &-close { top: $gridunit/2; }
    }
    H4 { margin: 1em 0 0 !important; }
  }
  TABLE {
    width: 100%; border-collapse: collapse;
    TH, TD { padding: $gridunit/2 $gridunit !important; text-align: left; vertical-align: top; }
    TH { background: $brand-primary-800-wcag; color: $color-white;}
    TR:nth-child(odd) TD { background: transparentize($brand-primary-800-wcag,0.95); }
    @include mobile { font-size: 16px; }
  }
}
