.layout-split {
  margin-bottom: $gridunit-large/-1;
  .frame-checkout & { margin-bottom: 0; }
  .content { overflow: visible; }
  @include not-mobile {
    display: flex;
    &.compact { height: 500px; margin-bottom: 0; }
    &__column {
      flex: 1; display: flex;
      &:first-child {
        justify-content: flex-end;
        .layout-split__limiter {
          padding-right: $gridunit*4; min-height: 80vh;
          //.frame-epharma__body & { padding-left: 0; }
        }
      }
      .compact & .layout-split__limiter { min-height: 0; }
      .reverse & {
        &:first-child { padding-right: 0; }
        &:last-child {
          padding-left: $gridunit*4; align-items: center;
        }
      }
    }
    &__limiter {
      flex: 1; max-width: calc(50vw - (50vw - #{$content-maxwidth/2})); padding: $gridunit; box-sizing: border-box;
      @include not-mobile {
        padding-bottom: $gridunit-large*4;
        .compact & { padding-bottom: $gridunit; }
      }
      .frame-epharma__body__limiter & { padding: $gridunit 0; }
    }
    &__bg { width: 100%; height: 100%; object-fit: cover; }
  }
  &__column {
    &.theme01 { background-color: $brand-accent-2-200; }
    &.theme02 { background-color: $brand-campaign-1; }
  }
  @include mobile {
    &__column {
      padding: $gridunit;
      .frame-epharma__body & { padding: 0; }
      &:last-child {
        padding: 0;
        .layout-split__limiter { padding: $gridunit; }
      }
      &:first-child {
        .layout-split__limiter {
          .frame-epharma__body & { padding: 0 $gridunit; }
        }
      }
    }
    &__bg { width: 100%; height: 440px; object-fit: cover; }
  }
}
