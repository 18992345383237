.frame-epharma {
  .banklinks BUTTON {
    width: 95px; height: 39px; border: none;
    .banklinks-item-image { background-size: contain; }
  }
  .radio-list {
    .radio-with-label {
      &.selected {
        border-color: $color-system-info-200;
        background-color: $color-system-info-200;
      }
    }
  }
  .product-campaign {
    &.theme06 { border-color: #FBEFF5; background-color: #FBEFF5; }
    &.theme07 { border-color: #B6E4E5; background-color: $color-white; }
    /*@include mobile {
      flex-direction: row; align-items: flex-start;
      &__image { width: 70px; height: 70px; flex: 0 0 70px; }
      &__inner { text-align: left; margin-top: 0; }
    }*/
  }
  @include not-mobile {
    .banklinks.layout-default {
      display: grid; grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 3px; margin-left: 0;
      > LI {
        margin: $gridunit-small 0 0;
        BUTTON {
          position: relative; box-sizing: border-box;
          .banklinks-item-image { left: -1px ; top: -1px; bottom: -1px; right: -1px; }
        }
      }
    }
  }
  @include not-desktop {
    &__header .is-sticky { position: static; }
  }
  &__header .userhub { order: 2; }
  &__languages {
    .languages-toggle {
      display: flex; justify-content: center; align-items: center;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        border-color: $color-gray-medium transparent transparent transparent;
        margin-left: $gridunit-small/2;
      }
      .label { line-height: 1.3; }
    }
    .#{$ns}-transition-container { transform: none !important; }
    .languages-drop {
      position: relative; left: -2px; top: 4px;
      @include mobile {
        left: -1px; top: 4px;
      }
    }
    .label {
      position: relative; left: 0;
      ABBR { border-bottom: none; text-decoration: none; font-size: $font-size-small; font-weight: $bold; }
    }
  }
}
@include not-mobile {
  .cart-products__item {
    .box-product {
      &__text { display: block; }
    }
  }
  .frame-epharma__body {
    overflow: visible;
  }
  .layout-cart {
    &__sidebar {
      position: sticky; top: 100px; max-height: calc(100vh - 320px);
      .has-timeout & {
        top: 140px; max-height: calc(100vh - 360px)
      }
      overflow: hidden; display: flex; flex-direction: column;
      &-details {
        flex: 0 1 100%; margin-right: -$gridunit-small; padding-right: $gridunit-small;
        @include scrollbars(); @include overflow();
      }
      .is-sticky & {
        max-height: calc(100vh - 190px);
      }
      .is-sticky.has-timeout & {
       max-height: calc(100vh - 230px);
     }
      @media only screen and (min-width: 1775px) {
        max-height: calc(100vh - 250px);
        .has-timeout & { max-height: calc(100vh - 290px); }
        .is-sticky & {
          max-height: calc(100vh - 120px);
        }
        .has-timeout.is-sticky & { max-height: calc(100vh - 160px); }
      }
    }
  }
}
@include mobile {
  .box-product {
    &__image { max-height: 180px; min-height: 0; }
    .cart-products__item & {
      &__actions {
        display: flex; justify-content: space-between;
        &-group {
          flex: 0 0 auto; display: block; min-width: 0;
          .box-product {
            &__remove { float: right; }
            &__total-prices {
              margin-right: 0;
              .text-accented { margin-right: 0; }
            }
          }
        }
      }
    }
    .button {
      font-size: $font-size-smaller !important; white-space: normal;
      &.intent-secondary {
        padding-left: $gridunit-small/2; padding-right: $gridunit-small/2;
        IMG { margin-left: 0 !important; margin-right: 0 !important; }
      }
      IMG,
      svg { height: 12px; }
    }
  }
  .frame-epharma {
    &__body { z-index: 1; }
    &__pre-header {
      position: relative; height: 3.8em; display: flex;
      &__limiter {
        justify-content: flex-end; align-items: center;
      }
      &__contacts {
        flex-wrap: wrap; justify-content: flex-end; position: relative; top: $gridunit-small/1.5; margin: 0;
        /*position: absolute; right: $gridunit; top: $gridunit; z-index: 11;*/
        > LI {
          margin-top: 0; margin-left: 0;
          A { padding:  0; }
        }
        .highlighted { flex: 0 0 100%; justify-content: flex-end; }

      }
    }
    &__header {
      .logo {
        margin-top: -3.8em; font-size: $base-font-size;
        IMG { max-width: 190px; max-height: 3em; min-height: 3em; width: auto !important; height: auto !important; object-fit: contain; object-position: center; }
      }
    }
    &__languages { margin-left: auto; }
    &__header__primary .frame-epharma__header__limiter { padding-bottom: 0; }
    &__pre-header__contacts > LI { margin-bottom: 0; }
  }
  .cart-products__item {
    .box-product {
      overflow: hidden;
      &__actions {
        overflow: hidden; max-width: 100%;
        &-group {
          overflow: hidden; flex: 0 0 50%; min-width: 125px;
          &:first-child { min-width: 0; }
          &:last-child { justify-content: flex-end; }
          .product-pricing__price-number { white-space: nowrap; }
          .button { margin: $gridunit-small 0 0; }
        }
      }
      &__total {
        min-width: 0; justify-content: flex-end; flex: 0 0 auto; max-width: 100%;
        P { max-width: 100%; }
        SPAN { flex-wrap: wrap; }
        &-prices {
          flex-direction: row;
          .text-accented { margin-right: $gridunit-small !important; }
        }
      }
      &__prices { flex: 0 0 auto; min-width: 0; }
    }
  }
}

.cart-products__item {
  .box-product {
    @include not-mobile {
      .box-product__total {
        flex: 1 0 auto;
        P { justify-content: flex-end; }
      }
      .box-product__actions-group {
          &:last-child {
            flex: 1 0 auto; justify-content: flex-end;
            .box-product__total { flex: 1 0 auto; }
          }
      }
    }
  }
}

.box-product {
  &__actions {
    display: flex; flex-wrap: wrap; justify-content: space-between;

    .layout-products & &-group,
    .carousel & &-group {
      display: flex; flex-wrap: wrap; justify-content: space-between; flex: 0 0 100%;
    }
    &,
    .layout-products & &-group,
    .carousel & &-group{
      > * {
        &.box-product__prices { flex: 0 0 100%; }
        &[data-component="addToCart"],
        &.button.intent-primary { flex: 0 0 100%; }
      }
      .button.size-small {
        font-size: $font-size-small; align-self: center;
        IMG { margin-left: 0; }
      }
      .box-product__quantity + .button.size-small { width: auto; }
    }
  }
  &__favourites-button {
    width: 48px; height: 48px; position: absolute; right: 0; bottom: 0; margin-left: auto; z-index: 4; border: none; background-color: transparent;
    text-indent: -10000px; overflow: hidden; cursor: pointer; border-radius: $base-radius;
    &:hover,
    &:active {
      background-color: transparentize($color-black, .95);
      IMG { transform: scale(1.3); }
    }
    &:active, { box-shadow: $shadow-focus2; }
    IMG { position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; transition: all $uispeed; }
  }
  &__image {
    padding-bottom: 48px; box-sizing: border-box;
  }
  &.special {
    .box-product {
      &__image {
        background-color: $color-white;
        SPAN { width: 100%; height: 100%; }
        &:before {
          content: ""; background-color: $color-white; position: absolute; left: -4px; top: -4px; right: -4px; bottom: 0; border-radius: $base-radius;
          @include mobile {
            left: -12px; right: -12px;
          }
        }
      }
    }
    .special-label { position: absolute; bottom: 16px; text-align: center; max-width: calc(100% - 60px); word-wrap: break-word; font-size: $font-size-small; font-weight: $bold; left: $gridunit-small; z-index: 2; background-color: $color-system-info-250; border-radius: 2em; padding: 0 $gridunit-small; }

  }
}

H2, H3, H4, H5, H6 {
  margin: 30px 0 0 0; padding: 0;
  @include mobile {
    .not-mobile-hide & { margin-top: 30px; }
  }
}

.content > H2 { margin-top: 30px; }

.product-compare__item A,
.box-product__title { text-transform: none; }

.frame-epharma__footer {
  UL.nav {
    A {
      text-decoration: underline;
      &:hover { opacity: 0.9; }
    }
  }
}

.layout-products__container {
  @media only screen and (min-width: 375px) and (max-width: 719px) {
    flex: 0 0 50%; max-width: 50%;
    .box-product__image { max-height: 200px; }
    .box-product__title { font-size: $font-size-medium; }
  }
  @media only screen and (max-width: 374px) {
    flex: 0 0 100%;
    .box-product__title { font-size: $font-size-medium; }
  }
}

.layout-product {
  &__content {
    H1 {
      .button { position: relative; top: -.3em; }
    }
  }
  &__image {
    .product-badges { pointer-events: none; }
  }
}

// CLS improvements

.product-filters__form { min-height: 55px; }
.rx-timer__inner { min-height: 44px; }
.layout-cart__main {
  &:not(:first-child:last-child) { min-height: calc(80vw - 150px); }
}
@include mobile {
  .frame-epharma {
    &__preheader { min-height: 48px; }
    &__header {
      min-height: 159px;
      .is-sticky & { min-height: 75px; }
    }
  }
}
@include tablet {
  .frame-epharma {
    &__preheader { min-height: 43px; }
    &__header {
      min-height: 159px;
      .is-sticky & { min-height: 75px; }
    }
  }
}
@include desktop {
  .frame-epharma {
    &__preheader { min-height: 40px; }
    &__header {
      min-height: 129px;
      .is-sticky & { min-height: 60px; }
    }
  }
}

.list-progress-item:last-child .list-progress-item-content {
  .layout-split { margin-bottom: 0; }
}

.carousel.carousel-inline {
  IMG { width: auto; }
  @media (min-width: 575px) and (max-width: 670px) {
    .banner.size-full {
      flex-basis: 0 !important; padding-bottom: 44%; position: relative;
      A { position: absolute; left: 0; right: 0; top: 0; bottom: 0; height: 100%; }
    }
  }
}

// Navigation active styles
.site-menu  {
  NAV > UL {
    .bp3-popover-open > A,
    A {
      &.active {
        background-color: transparent;
        color: $color-text-dark;
        text-decoration: underline;
        SPAN.toggle-icon IMG { top: 0; }
        &:hover {
          background-color: $color-background-mainmenu-level1-hover;
          color: $brand-primary-800-wcag;
          SPAN.toggle-icon IMG { top: 0; }
        }
      }
      &.hilited {
        background-color: $color-background-mainmenu-level1-selected;
        color: $color-text-light;
        SPAN.toggle-icon IMG { top: -20px; }
        &:hover {
          background-color: $color-background-mainmenu-level1-selected-hover;
          color: $color-text-light;
          SPAN.toggle-icon IMG { top: -20px; }
        }
      }
    }
  }
  &__drop{
    &__nav {
      A {
        &.active {
          background-color: transparent; text-decoration: underline; color: $color-text-dark;
          svg path { fill: $color-black; }
          &:hover { background-color: $color-background-mainmenu-level2-hover; }
        }
      }
    }
    &__content {
      UL LI{
        &.level-3 > A,
        &.level-4 > A {
          &:hover { background-color: $color-background-mainmenu-level2-hover; text-decoration: none; }
          &.active {
            text-decoration: underline;
            &:hover { text-decoration: underline; }
          }
        }
      }
    }
  }
}

.user-menu-items LI > A.button { border: 1px solid $brand-gray-placeholder-light; }

.frame-epharma__header__nav {
  @include not-desktop {
    border-bottom: none; box-shadow: $shadow-menus03; position: fixed; left: 0; right: 0; bottom: 0;
    .toggler,
    .mini-cart {
      text-decoration: none; font-size: $font-size-smaller; font-weight: $bold; padding: $gridunit-small 0; letter-spacing: -0.08em;
    }
    .frame-epharma__header__limiter {
      > DIV { flex: 0 0 20%; }
    }
  }
}

.frame-epharma__header {
  .menu-toggle IMG { width: 24px; height: 24px; }
  @include mobile {
    min-height: 89px;
  }
}

.otc-form-group { background-color: $color-system-info-200; }
.product-badges__group,
.box-product__badges {
  &:first-child:last-of-type { flex: 0 0 100%; }
}
.box-product__badge-circle,
.product-badges__badge-circle { border-radius: $base-radius; align-self: flex-end; }

.list-icons {
  > LI { margin-left: 72px; }
  &__illustration { background-color: transparent; width: 72px; height: 72px; border-radius: 0; left: -72px; }
}

.frame-checkout {
  .list-progress-item-title {
    &:before {
      top: -5px; left: -5px;
    }
  }
}

.product-pricing__price.special { order: 2; }
.cart-products__item {
  .box-product {
    &__total {
      SPAN.box-product__total-prices {
        display: flex; flex-direction: column;
        .text-accented { order: 2; }
      }
    }
    .product-pricing {
      align-items: flex-start;
      .product-pricing__price {
        &,
        &.special {
          P {
            display: flex; flex-direction: column;
            .product-pricing__price {
              &-label { text-indent: 0; width: auto; display: flex; align-items: center; }
              &-value { align-self: flex-start; }
            }
          }
        }
        &.special {
          .product-pricing__price-label {
            IMG { position: static; }
          }
        }
      }
    }
    @include not-desktop-very-wide {
      &__actions { padding-left: 290px; }
    }
    @media only screen and (min-width: 995px) and (max-width: 1340px) {
      &__actions { padding-left: 0; }
      .box-product {
        &__prices {
          flex: 0 0 auto;
          .product-pricing { flex-direction: row; align-items: flex-end; }
        }
        &__total {
          flex: 0 0 auto;
          SPAN.box-product__total-prices { flex-direction: row; justify-content: flex-end; gap: $gridunit-small/2; }
        }
        &__remove { margin-left: $gridunit*2 !important; }
      }
    }
    @include tablet {
      .box-product {
        &__prices {
          flex: 0 0 auto;
          .product-pricing { flex-direction: row; align-items: flex-end; }
        }
        &__total {
          flex: 0 0 auto;
          SPAN.box-product__total-prices { flex-direction: row; justify-content: flex-end; gap: $gridunit-small/2; }
        }
        &__remove { margin-left: $gridunit*2 !important; }
      }
    }
    @include mobile {
      &__actions {
        flex-direction: column;
        &-group {
          min-width: 0; max-width: 100% !important; align-self: stretch; display: flex; justify-content: space-between !important;
          .product-pricing { align-items: flex-end; margin-right: $gridunit-small; }
          .box-product__total SPAN.box-product__total-prices {
            flex-direction: row; align-items: flex-end;
            > SPAN:first-child { margin-right: 0 !important; }
          }
          .box-product__remove { margin-left: 0; }
        }
      }
      .box-product {
        &__prices {
          .product-pricing { flex-direction: row; align-items: flex-end; }
        }
      }
    }
  }
  &.wishlist {
    .box-product__actions-group {
      &:first-child {
        max-width: none; flex: 0 0 100%;
        .button {
          margin-left: $gridunit; margin-top: 0;
          @include mobile {
            margin-top: $gridunit; margin-left: 0; margin-right: 60px; flex: 0 1 100%; max-width: none;
          }
        }
      }
      &:not(:first-child) {
        @include not-desktop {
          margin: 0;
        }
        @include not-desktop-very-wide {
          margin: 0;
        }
      }
    }
    .box-product__remove {
      @include not-desktop { position: absolute; right: 0; bottom: 0; }
      @include not-desktop-very-wide { position: absolute; right: 0; bottom: 0; }
    }
  }
}


.rx-chat {
  @include mobile {
    &__toggle {
      position: absolute; right: $gridunit; bottom: 90px; width: auto; padding: 0; border-radius: 50%; overflow: hidden; box-shadow: $shadow02;
      SPAN {
        margin: 0;
        &.rx-chat__toggle-icon { width: 42px; height: 42px; padding: 3px; box-sizing: border-box; display: flex; justify-content: center; align-items: center; }
        &:not([class]) { display: none; }
      }
    }
  }
  @media only screen and (min-width: 760px) and (max-width: 995px) {
    bottom: 90px;
  }
}

BODY > .#{$ns}-portal .#{$ns}-overlay:not(.control-select-portal) {
  @include not-desktop {
    position: fixed; z-index: 20; left: 0; top: 0; right: 0; bottom: 0;
  }
}
.layout-cart__sidebar.mobile-sticky {
  @include mobile {
    top: auto; bottom: 72px !important; border-bottom: 3px solid $brand-gray-400;
    &.expanded { top: 0; }
    .buttons.layout-default { margin-right: 50px; }
  }
}

.frame-epharma__header-item.desktop-hide {
  @include desktop { display: none !important; }
}

.layout-sidebar {
  @include desktop { margin-top: $gridunit-large; }
  .frame-epharma__page-header__limiter {
    padding: 0;
    .breadcrumb:first-child { margin-top: 0; }
  }
}
.frame-epharma__page-header {
  .breadcrumb:first-child {
    @include desktop { margin-top: $gridunit-large; }
  }
}

.box-product.selectable {
  @include not-mobile {
    .box-product {
      &__main { align-items: center; }
      &__image,
      &__content { align-self: center; }
      &__content,
      &__text { justify-content: center; }
      &__description { min-height: 0; }
      &__prices { margin-top: 0; }
    }
  }
}

.search__results__result {
  A.special { border: 1px solid rgba($color-black, 0.15); border-radius: 3px; margin: 3px 0; }
  &-tag {
    font-size: $font-size-smallest; display: inline-block; padding: 0 $gridunit-small/2; border-radius: 2em; background-color: $color-system-info-250;
    :hover & { color: $color-black; }
  }
}

.cart-actions__primary,
.cart-actions__secondary {
  .button { max-width: none !important; }
}

@include mobile {
  .userhub-mobile .label {
    &.logged-in {
      .account-title { display: none; }
      .account-subtitle { display: block; }
    }
    &:not(.logged-in) {
      .account-title { display: block; }
      .account-subtitle { display: none; }
    }
  }
}

.cart-products {
  @include not-mobile {
    &__item {
      padding-bottom: $gridunit-large; margin-top: $gridunit-large; border-bottom: 3px solid lighten($brand-gray-300, 2%);
    }
  }
  @include mobile {
    border: none; padding: 0;
    &__item {
      border: 1px solid darken($brand-gray-300, 5%); border-radius: $base-radius; padding: $gridunit-small; margin: $gridunit-small 0 0;
      &:last-child { border-bottom: 1px solid darken($brand-gray-300, 5%); }
    }

  }
}

@include mobile {
  .products-carousel .carousel-inline.carousel .control-arrow {
    top: 100px; height: 40px;
    &:before,
    svg { top: 50%; }
  }
}

.gallery .carousel {
  @include not-mobile {
    .control-arrow { display: none; }
    .control-dots { display: none; }
  }
  @include mobile {
    padding-bottom: $gridunit*2;
    .gallery__slide { padding: 0 40px; }
    .control-dots { z-index: 2; }
    .control-arrow { background-color: $brand-gray-300; }
  }
}

.carousel .control-arrow:focus {
  &:before { box-shadow: $shadow-focus-inset; }
}

.social-links > LI A { border-radius: 3px; }

.layout-product__image .special-label {
  @include mobile {
    bottom: $gridunit*2;
  }
}
.product-image .gallery { padding-bottom: 400px; margin-bottom: $gridunit; }

.list-accordions__item__content {
  padding: $gridunit 0;
  TABLE:not([class]) TD { white-space: normal; }
  .layout-product__tabs & { padding: $gridunit $gridunit $gridunit $gridunit-large*2; }
}

.box-product {

  &__actions {
    @include mobile { .button.layout-default { max-width: none; }}
  }
  &__description-secondary { font-style: normal; }
  &__secondary-actions { display: flex; gap: $gridunit-small; align-items: center; align-self: center; }
  &__favourites-button {
    position: relative; top: $gridunit-small; right: 0; margin: 0 $gridunit*-1 0 $gridunit-small*-1.5; align-self: center;
    .box-product__image & { position: absolute; top: auto; bottom: 0; }
    &:focus { box-shadow: $shadow-focus2; background-color: $brand-gray-200; }
  }
  &__rating {
    align-self: center; margin: $gridunit 0 0 auto; font-size: $font-size-small;
    @include mobile { margin-left: 0; }
    IMG {
      height: .8em; width: auto;
      &:last-of-type { margin-right: $gridunit-small/2; }
    }
  }
  &__prices { margin-top: $gridunit-small/2; }
  .product-pricing {
    .product-pricing__price-label,
    .product-pricing__price-prefix { font-weight: $medium; }
    &__price {
      color: $color-text-dark;
      &:first-child { margin-right: 0; }
      &:not(:first-child) {
        @include not-mobile { margin-right: $gridunit; }
      }
      &.special .product-pricing__price-value { color: $brand-primary-800-wcag; }
    }

    &__price-number { font-size: $font-size-large; }
    &__price.discount {
      .product-pricing__price-number { font-weight: $medium; }
    }
  }

  &__title {
    display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; flex: 0 0 auto; max-height: none; min-height: 4.5em; margin-top: $gridunit-small/2;
    font-size: $font-size-medium-small; line-height: 1.4;
  }
  &__description { margin: $gridunit-small 0; min-height: 3em; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; }
  .product-pricing__price P:first-child:last-child { padding-bottom: 0; }
  @include mobile {
    &__title { min-height: 3.6em; }
    padding: $gridunit-small $gridunit-small/2;
    &__description { min-height: 0; margin: $gridunit-small/2 0; }
    &__quantity { margin: $gridunit-small 0 0; }
    &__rating { margin-top: $gridunit-small; }
    &__favourites-button { margin-top: -$gridunit-small*2; margin-bottom: -$gridunit-small; }
  }

  &__badge-circle {
    @include mobile { width: 3em; min-height: 3em; }
  }
}

.layout-products__list.light {
  @include mobile { margin: 0 $gridunit-small*-1; }
}

.visible-focus .search__inner {
  &.active,
  &:focus {
    &:before { z-index: 2;}
    > * { z-index: 1; }
    &:after {
      content: ""; border-width: 4px; box-shadow: $shadow-focus2; border-radius: 20px;
      position: absolute; left: 0; top: -1px; bottom: -1px; right: 0; z-index: 0;
    }
  }
}

