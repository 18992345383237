.layout-bg {
  margin-bottom: $gridunit-large/-1; position: relative; padding: $gridunit-large*2 $gridunit-large;
  &__bg { position: absolute; left: 0; top: 0; right: 0; bottom: 0; width: 100%; height: 100%; object-fit: cover; object-position: center; }
  &__content {
    position: relative; max-width: $content-maxwidth; margin: 0 auto;
    &-inner {
      background-color: rgba($color-white,0.9); padding: $gridunit; max-width: $content-maxwidth/2;
      .tabbed-content.elevated,
      .elevated .tabbed-content__panel-content { background-color: transparent; padding: 0; box-shadow: none; }
      .form-control-password { background-color: $color-white; }
    }
  }
  @include mobile {
    padding: 0;
    &__bg { display: none; }
  }
}
