.visuallyhidden { position: absolute; left: -10000px; }
.sticky { position: sticky; }
.mt10 { margin-top: 10px !important; }
.mt20 { margin-top: 20px !important; }
.mt1em { margin-top: 1em !important; }
.mt2em { margin-top: 2em !important; }
.nomargin { margin: 0 !important; }

.w50 { width: 50px !important; }
.w70 { width: 70px !important; }
.w100 { width: 100px !important; }

