@import 'variables';

// Responsive sets

@mixin tiny { @media only screen and (max-width: 239px) { @content; } }
@mixin mobile { @media only screen and (max-width: 759px) { @content; } }
@mixin mobile-wide { @media only screen and (min-width: 450px) and (max-width: 759px) { @content; } }
@mixin not-mobile { @media only screen and (min-width: 760px) { @content; } }
@mixin tablet { @media only screen and (min-width: 760px) and (max-width: 1023px) { @content; } }
@mixin desktop { @media only screen and (min-width: 1024px) { @content; } }
@mixin not-desktop { @media only screen and (max-width: 1023px) { @content; } }
@mixin desktop-wide { @media only screen and (min-width: 1281px) { @content; } }
@mixin desktop-very-wide { @media only screen and (min-width: 1440px) { @content; } }
@mixin desktop-ultra-wide { @media only screen and (min-width: 1920px) { @content; } }
@mixin not-desktop-wide { @media only screen and (max-width: 1280px) { @content; } }

//

@mixin defaultPadding {
	@include mobile { padding: $base-padding-mobile; }
	@include tablet { padding: $base-padding-tablet; }
	@include desktop { padding: $base-padding-desktop; }
}
@mixin defaultPaddingTop($offset: 0) {
	@include mobile { padding-top: $spacer-mobile-vertical - $offset; }
	@include tablet { padding-top: $spacer-tablet-vertical - $offset; }
	@include desktop { padding-top: $spacer-desktop-vertical - $offset; }
}

@mixin defaultMargin($offset: 0) {
	@include mobile { margin-top: $spacer-mobile-vertical - $offset; }
	@include tablet { margin-top: $spacer-tablet-vertical - $offset; }
	@include desktop { margin-top: $spacer-desktop-vertical - $offset; }
}

// Clearing

@mixin clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// Grids

@mixin mainGrid() {
	// 12 columns split in half and 24px gaps
	display: grid;
	grid-template-columns: 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr $grid-gap 1fr 1fr;
	grid-template-rows: 1fr;
	> * { width: 100%; }
}

@mixin inlineico($size) {
	.icon {
		position: relative; width: $size; height: 2px; vertical-align: middle; display: inline-block; margin-top: -2px;
		svg { width: $size; height: $size; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
		path { fill: currentColor; stroke: currentColor; }
	}
}

@mixin overflow($displayScrollbars: false) {
	overflow: hidden;
	@if $displayScrollbars == true {
		overflow-y: scroll;
	} @else {
		overflow-y: auto;
	}
	-ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto;
}
@mixin scrollbars($dark: false) {
	&::-webkit-scrollbar { width: 10px; height: 10px; }
	&::-webkit-scrollbar-button { width: 0; height: 0; }
	&::-webkit-scrollbar-thumb { border-radius: $base-radius; }
	&::-webkit-scrollbar-track { border-radius: $base-radius; }
	@if $dark == true {
		&::-webkit-scrollbar-thumb { background: rgba($color-white, 0.5); }
		&::-webkit-scrollbar-thumb:hover { background: $color-white; }
		&::-webkit-scrollbar-thumb:active { background: $color-white; }
		&::-webkit-scrollbar-track { background: rgba($color-white, 0.1); border-radius: $base-radius; }
		&::-webkit-scrollbar-track:hover { background: rgba($color-white, 0.2); }
		&::-webkit-scrollbar-track:active { background: rgba($color-white, 0.3); }
		&::-webkit-scrollbar-corner { background: transparent; }
	} @else {
		&::-webkit-scrollbar-thumb { background: rgba($color-black, 0.1); }
		&::-webkit-scrollbar-thumb:hover { background: $color-black; }
		&::-webkit-scrollbar-thumb:active { background: $color-black; }
		&::-webkit-scrollbar-track { background: rgba($color-black, 0.05); border-radius: 0; }
		&::-webkit-scrollbar-track:hover { background: rgba($color-black, 0.1); }
		&::-webkit-scrollbar-track:active { background: rgba($color-black, 0.1); }
		&::-webkit-scrollbar-corner { background: transparent; }
	}
}

@mixin animBounce($amount) {
	@keyframes bounce-#{$amount} {
		0%, 10%, 25%, 40%, 50% { transform: translate3d(0, 0, 0); }
		25% { transform: translate3d(0, 0, 0) scaleY(0.9); }
		20% { transform: translate3d(0, -$amount, 0); }
		30% { transform: translate3d(0, $amount / -2, 0); }
	}
	animation: bounce-#{$amount} 4s $uieasing infinite;
    animation-delay: 2s;
}
