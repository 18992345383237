@import "../../../scss/variables";
@import "../../../scss/mixins";

.icon-preview {
	overflow: hidden; margin: 10px -10px 0 0;
	> DIV {
		float: left; width: 220px; margin: 10px 10px 0 0; font-size: 11px; border: 1px solid rgba($color-black, 0.1); border-radius: 5px; box-sizing: border-box; padding: 10px; text-align: center; background-color: rgba($color-black, 0.05);
		svg { height: 50px; max-width: 150px; }
	}
}
