.epharma-dialog {
  &-heading {
    position: relative;
    BUTTON {
      cursor: pointer; border-radius: 50%; border: none; width: 55px; height: 55px; display: flex; justify-content: center; align-items: center;
      position: absolute; right: -$gridunit*2; top: -$gridunit*2;
      background: $color-white; box-shadow: 0 0 8px rgba(77, 77, 77, 0.4);
      svg {
        display: block; width: 16px !important; height: 16px; margin: auto; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
        path { fill: $brand-primary-800-wcag; }
      }
      &:focus {
        .visible-focus & { box-shadow: $shadow-focus; }
      }
      @include mobile {
        width: 42px; height: 42px; right: -$gridunit-large; top: -$gridunit-large;
      }
    }
    H2 { margin: 0; font-weight: $bold; }

  }
  .overlay-dialog {
    &-holder {
      padding: $gridunit-large $gridunit-large $gridunit-large*5 $gridunit-large;
      @include mobile {
        padding: $gridunit;
      }
      @include tablet {
        padding: $gridunit $gridunit $gridunit*5 $gridunit;
      }
    }
    &-content {
      max-width: 680px; padding: $gridunit; text-align: left;
      IMG { max-width: 100%; }
    }
  }
  &.wide .overlay-dialog {
    &-content { max-width: 840px; }
  }
  &-fullsize {
    .overlay-dialog-content { padding: 0; }
  }
  &-content {
    .button.layout-default {
      max-width: none;
      @include mobile { max-width: 100%; }
      .text { white-space: normal; }
    }
  }
}

.overlayholder.overlayholder-focusview {
  @include mobile { z-index: 999 !important; }
}

.overlay-search {
  .alternate-blocks { max-width: 100%; }
}

.overlay-focusview-content {
  display: flex; flex-direction: column;
}

.overlay-focusview-scroller {
  @include mobile {
    flex: 0 1 100% !important; height: auto !important;
  }
}

.bp5-popover-transition-container,
.bp5-overlay-start-focus-trap {
  .visible-focus &:focus { box-shadow: $shadow-focus; }
}
