@import "../variables";
@import "../mixins";

@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/tag/common";

.default-badge {
    display: inline-block;
    padding: 0 $gridunit-small;
    box-sizing: border-box;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    font-weight: $bold;
    min-width: 80px;
    letter-spacing: 1px;
    text-align: center;
    font-size: $font-size-small;
    &.#{$ns}-interactive {
        outline: none; border: none; cursor: pointer;
        &:active, &:focus {
            outline: none; border: none;
        }
    }
}
