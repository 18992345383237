@import 'variables';

@-ms-viewport { width: device-width; }

HTML, BODY { padding: 0; margin: 0; overscroll-behavior-y: none; -webkit-overflow-scrolling: touch; }

HTML { font-size: 62.5%; } // Bootstrap rm unit reset
HTML { overflow: -moz-scrollbars-vertical; -webkit-tap-highlight-color: rgba($color-black, 0); -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
HTML.fixed { margin-left: calc(100vw - 100%); }
BODY { -ms-interpolation-mode: nearest-neighbor; position: relative; }

article, aside, footer, header, nav, section, figcaption, figure, main { display: block; }

hr { box-sizing: content-box; height: 0; overflow: visible; }

pre, code, kbd, samp { font-family: monospace, monospace; font-size: 1em; }

svg {
	circle[fill="none"],
	path[fill="none"] { fill: transparent !important; }
	&:not(:root) { overflow: hidden; }
}

A { -webkit-text-decoration-skip: objects; }
B { font-weight: $bold; }

audio, video { display: inline-block; }
audio:not([controls]) { display: none; height: 0; }
img { border-style: none; }

button, input, optgroup, select, textarea, option, label { font-size: inherit; font-family: inherit; line-height: 1.15; color: inherit; margin: 0; border-radius: 0; box-sizing: border-box; max-width: 100%; text-transform: inherit; font-weight: inherit; }
button, input { overflow: visible; }
button, select { text-transform: none; }
button, html [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; outline: none; }
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { border-style: none; padding: 0; }
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring { outline: 1px dotted ButtonText; }
fieldset { border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend { box-sizing: border-box; color: inherit; display: table; max-width: 100%; padding: 0; white-space: normal; }
progress { display: inline-block; vertical-align: baseline; }
textarea { overflow: auto; }
[type="checkbox"],
[type="radio"] { box-sizing: border-box; padding: 0; }
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button { height: auto; }
[type="search"] { -webkit-appearance: textfield; outline-offset: -2px; }
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

[type="number"] { -moz-appearance: textfield; }
[type="number"]::-webkit-outer-spin-button,
[type="number"]::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none; margin: 0;
}

::-webkit-file-upload-button { -webkit-appearance: button; font: inherit; }
details, menu { display: block; }
summary { display: list-item; }
canvas { display: inline-block; }
template { display: none; }
[hidden] { display: none; }

P, .p { padding: 0; margin: $base-margin; }

@supports (-webkit-overflow-scrolling: touch) {
	INPUT,
	SELECT,
	TEXTAREA,
	SELECT:focus,
	TEXTAREA:focus,
	INPUT:focus { font-size: 16px !important; }
}

IFRAME { width: 100%; border: 0 none; margin: 0; }
