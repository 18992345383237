.banner {
  &.size-full {
    position: relative;
    A:not([class]), DIV.banner__img {
      display: block; position: relative; overflow: hidden;
      @include banner-large { height: 420px; }
      @include banner-medium-large { height: 0; padding-bottom: 31.5%; }
      @include banner-medium { height: 345px; }
      @include banner-small { height: 500px; }
    }
    PICTURE {
      position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 100%; min-width: 100%; text-align: center;
      IMG { height: 100%; object-fit: cover; object-position: center; }
    }
    .banner__text {
      position: absolute; left: 0; right: 0; bottom: 0; top: 0; color: $color-text-light; background-color: transparentize($color01, .5); display: flex; flex-direction: column; justify-content: center; align-items: center;
      text-align: center;
      &-inner {
        padding: $gridunit;
        > *:first-child { margin: 0; }
      }

    }
    .split & {
      .banner__text {
        left: 50%; background-color: transparent; align-items: flex-start; color: $color-text-dark; line-height: 1.5; padding-right: $gridunit-large; text-align: left;
        &-inner > *:first-child { margin: 0; }
      }
      @include not-desktop {
        .banner__text {
          position: static; padding: $gridunit*3 $gridunit $gridunit; text-align: center;
          H1, H2, H3, H4, H5, H6 { color: $brand-primary-800; }
        }
      }
      @include mobile {
        .banner__img {
          height: 188px;
          PICTURE {
            height: 100%; width: 100%;
            IMG { width: 100%; height: 100%; object-fit: cover; object-position: top center; }
          }
        }
      }
    }
    &.has-custom-height {
      > A { height: 100%; }
    }
  }
}
.banners {
  .visible-focus & A:focus {
    &:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: $shadow-focus-inset; z-index: 2;}
  }
  &.wrap {
    display: flex; flex-wrap: wrap;
    .frame-epharma__section__limiter & { margin: 0 $gridunit-small/-1; }
    .banner {
      flex: 1; box-sizing: border-box;
      A:not([class]) { display: block; overflow: hidden; position: relative; }
      &.size-full {
        flex: 0 0 100%; width: 100%;
        &.compact {
          @include mobile {
            A { height: 200px; }
          }
        }
      }
      &.size-medium {
        margin-top: $gridunit-small; margin-bottom: $gridunit-small;
        IMG { position: absolute; left: 50%; top: 50%; height: 100%; transform: translate(-50%, -50%); }
        @media (min-width: 1041px) {
          min-width: 50%; max-width: 50%;
          A { margin: 0 $gridunit-small; }
        }
        A {
          @include mobile { height: 274px;  }
          @media (max-width: 495px) { padding-bottom: 56.87%; height: 0; }
          @include not-mobile { height: 278px; }
        }
        @include desktop-wide {
          flex: 0 0 50%;
        }
        @media (max-width: 1040px) {
          flex: 0 0 100%; margin: $gridunit-small 0;
        }
      }
      &.size-small {
        IMG { position: absolute; left: 50%; top: 50%; height: 100%; transform: translate(-50%, -50%); }
        A {
          display: block; height: 250px; position: relative; overflow: hidden;
        }
        @media (min-width: 480px) {
          flex: 0 0 33.333%; min-width: 320px;
          A { margin: 0 $gridunit-small; }
          .layout-grid__column.size02 & {
            flex: 0 0 100%; margin: $gridunit 0 0;
            A { margin: 0; }
          }

        }
        @media (max-width: 479px) { flex: 0 0 100%; margin: $gridunit-small 0; }
      }
    }
  }
  &.scroll {
    @media (min-width: 480px) {
      display: flex; margin: 0 $gridunit-small/-1; overflow-x: auto; padding-bottom: $gridunit;
      &::-webkit-scrollbar {
        width: 9px; height: 9px;
        &-track { background-color: transparent; }
        &-thumb {
          background-color: $color-gray-light; border-radius: 5px; transition: all $uispeed $uieasing; cursor: pointer;
          &:hover {
            background-color: $color-gray-medium;
          }
        }
      }
    }

    .banner {
      &.size-full {
        flex: 0 0 100%;
      }
      &.size-small {
        IMG { position: absolute; left: 50%; top: 50%; height: 100%; transform: translate(-50%, -50%); }
        A {
          display: block; height: 250px; position: relative; overflow: hidden;
        }
        @media (min-width: 480px) {
          flex: 0 0 33.333%; min-width: 320px;
          A { margin: 0 $gridunit-small; }
        }
        @media (max-width: 479px) { flex: 0 0 100%; margin: $gridunit-small 0; }
      }
    }
  }
  + .banners { margin-top: $gridunit !important; }
}
.banners-brands {
  display: flex; flex-wrap: wrap; justify-content: center; margin: $gridunit 0 0;
  LI {
    flex: 0 0 160px; margin: $gridunit-small/2; box-sizing: border-box; text-align: center;
    @include mobile { flex: 0 0 120px; }
  }
  IMG { max-width: 100%; height: auto; }
  A {
    display: block;
    .visible-focus &:focus { box-shadow: $shadow-focus; }
  }
}

HTML .carousel {
  .control-pause,
  .control-page {
    border: 1px solid $brand-gray-placeholder-light;
    &.current {
      background-color: $brand-primary-800-wcag; color: $color-white; border-color: $brand-primary-800-wcag;
      &:hover { color: $color-white; background-color: $brand-primary-900; border-color: $brand-primary-900; }
    }
    &:hover { color: $brand-primary-800-wcag; border-color: $brand-primary-800-wcag; }
  }
}
.frame-epharma .carousel {
  .control-page,
  .control-pause {
    &.current {
      background-color: $brand-primary-800-wcag;
      &:hover { background-color: $brand-primary-900;}
    }
    &:hover { color: $brand-primary-800-wcag; }
    &:focus { box-shadow: $shadow-focus2; }
  }
}
