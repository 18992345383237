// Shopping cart

.layout-cart {
  margin: $gridunit;
  .frame-epharma__body__limiter & { margin: 0; }
  &__sidebar {
    margin: 30px 0 0; padding: $gridunit; box-sizing: border-box;
    background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: $base-radius;
    H2 { margin: 0; }
    &-icon {
      margin: -52px $gridunit 0 auto; width: 72px; height: 72px;
      background: $color-white; box-shadow: 0 8px 36px rgba(77, 77, 77, 0.16); border-radius: 50%;
      display: flex; justify-content: center; align-items: center;;
    }
    > .buttons {
      @include not-mobile { max-width: 100%; }
      > LI { flex: 0 0 100%; }
      @include not-desktop {
        .button { width: auto !important; min-width: 50% !important; max-width: 350px; }
      }
      @include mobile {
        > LI { justify-content: center !important; }
        .button { width: 100% !important; max-width: 350px !important; }
      }
    }
    &-toggle { display: none; }
    .button-primary,
    .button.intent-primary { width: 100%; margin: $gridunit 0 0; }
    &-total { line-height: 1.2; font-size: $font-size-larger; font-weight: $bold; display: flex; justify-content: space-between; }
    @include not-mobile {
      &.mobile-sticky { display: none; }
    }
    @include mobile {
      margin-top: 60px;
      &.mobile-sticky {
        position: fixed; left: 0; right: 0; bottom: 0; max-height: calc(100vh - 75px); z-index: 99; overflow: hidden; margin-top: 0;
        .rx-chat ~ .frame-epharma__body & {
          bottom: 3em;
        }
        > *:not(.layout-cart__sidebar-details) { flex: 0 0 auto; }
        > .buttons { flex: 0 0 50px; margin-top: 0; margin-bottom: 0; }
        box-shadow: $shadow-menus;
        display: flex; flex-direction: column;
        &.expanded {
          top: 75px;
          .layout-cart__sidebar {
            &-details { display: block; }
            &-total { margin-top: $gridunit; }
          }
        }
        .layout-cart__sidebar {
          &-icon { display: none; }
          &-total { margin: 0;}
          &-details { display: none; flex: 0 1 100%; overflow: auto; margin: 0 $gridunit*-1; padding: 0 $gridunit; }
          &-toggle { border: none; background-color: transparent; display: block; }
        }
      }

      &-total .text-accented { flex: 0 1 100%; text-align: right; }
    }
  }
  &__main {
    H1 { margin: 0; }
    .product-campaign:first-child { margin: 0; }
  }
  @include desktop {
    display: flex; flex-wrap: nowrap; align-items: flex-start;
    &__sidebar {
      flex: 0 0 280px; margin-top: 0; margin-left: $gridunit-large;
    }
    &__main {
      flex: 1 1 auto; max-width: calc(100% - 280px - #{$gridunit-large});
      &:first-child:last-child { max-width: 100%; }
    }
  }
}

.cart-products {
  border: 1px solid $brand-gray-200; margin: $gridunit 0 0;
  &__title {
    background-color: $brand-gray-200; padding: $gridunit-small; border-radius: $base-radius $base-radius 0 0;
    H2 { font-size: $base-font-size; font-weight: $normal; margin: 0; }
    IMG { margin-right: $gridunit-small; }
  }
  &__item {
    margin: $gridunit $gridunit-small 0; padding-bottom: $gridunit-small; border-bottom: 1px solid $brand-gray-200;
    &:last-child { border-bottom: none; }
    .box-product {
      padding: 0; position: relative; width: auto;
      &__quantity { margin: 0; }
      &__prices { flex: 0 0 120px; margin: 0; }
      .product-pricing {
        flex-direction: column; align-items: flex-end;
        &__price {
          P:first-child:last-child { padding-bottom: 0; }
          &-value:first-child { margin-top: 0; }
          &-number { font-size: $font-size-medium-large; line-height: 1.4; }
          &.special {
            P { display: flex; }
            .product-pricing__price-label {
              text-indent: -10000px; display: inline-block; width: 16px; margin: $gridunit-small/2 $gridunit-small/2 0 0; height: 16px; position: relative;
              IMG { width: 16px; height: 16px; border-radius: 3px; position: absolute; left: 0; top: 0; }
            }
          }
        }
      }
      &__main { overflow: hidden; }
      &__description-secondary {
        SPAN { white-space: normal; word-wrap: break-word; }
      }
      &__description {
        @include mobile { min-height: 0; }
      }
      &__total {
        flex: 1; text-align: left; margin-left: $gridunit-small;
        P {
          margin: 0; font-size: $font-size-small; font-weight: $medium-bold; display: flex; text-transform: uppercase; align-items: center;
        }
        &-prices {
          margin-left: $gridunit-small/2; flex: 0 0 120px;
        }
        SPAN {
          display: block; font-size: $font-size-medium-large; color: $brand-gray-placeholder; white-space: nowrap;
          DEL {
            position: relative;
            text-decoration: none;
            font-weight: $normal;
            &:before {
              content: ""; position: absolute; z-index: 1; top: 50%; left: -6%; opacity: 0.8; width: 100%; height: 1px; background: $brand-gray-600; transform: rotate(-15deg);
            }
          }
          &.text-accented { color: $brand-primary-800-wcag; }
          &.box-product__total-prices {
            &-label {
              font-size: $font-size-smallest; text-transform: none; display: flex; align-items: center;
              IMG { margin-right: 4px; }
            }
          }
          SPAN { color: inherit; }
        }
        IMG { width: $gridunit; height: $gridunit; border-radius: 3px; }

      }
      &__remove {
        position: relative; border: 1px solid $brand-gray-placeholder-light; background-color: $brand-gray-300; margin-left: $gridunit-large;
        text-indent: -10000px; width: 38px; height: 38px; box-sizing: border-box; cursor: pointer; border-radius: $base-radius;
        &:hover { background-color: $brand-gray-350; }
        &:focus { box-shadow: $shadow-focus2; }
        IMG { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: $gridunit; height: $gridunit; margin: auto; }
      }
      &__loyalty {
        align-items: center; line-height: 1.3; position: relative; padding-left: 30px;
        STRONG { display: block; }
        IMG { margin-right: $gridunit-small; width: 22px; height: 22px; position: absolute; left: 0; top: 0; }
      }
      &__actions {
        display: flex; align-items: center; flex-wrap: wrap; flex: 0 0 100%; box-sizing: border-box; align-self: flex-start; justify-content: space-between;
        &-group {
          display: flex; align-items: center; flex: 0 0 auto; align-self: flex-start; margin: $gridunit 0 0; flex-wrap: wrap;
          &:first-child {
            min-width: 24rem; max-width: 24rem;
            @include tablet { max-width: none; }
          }
          .button {
            flex: 0 0 6.6em; margin: $gridunit-small 0 0 7.5em;
            @include mobile { margin: $gridunit-small 0 0 0; flex: 0 0 8.8em; max-width: 8.8em; }
            @include tablet { margin: 0 0 0 $gridunit-small; }
            @include not-desktop-wide { margin: 0 0 0 $gridunit-small; }
          }
          @include tablet { flex-wrap: nowrap; }
          @include not-desktop-wide { flex-wrap: nowrap; }
        }
      }
      .system-notifications { flex: 0 0 100%; }

      @include not-mobile {
        flex-direction: row; flex-wrap: wrap;
        &__main {
          display: flex; margin-right: $gridunit-large; flex: 0 1 100%; flex-direction: row;
        }
        &__image { flex: 0 0 262px; margin-right: $gridunit-large; }
      }
      @include desktop-very-wide {
        flex-wrap: nowrap;
        .system-notifications { order: 2; }
        &__remove { position: absolute; right: $gridunit-small; top: $gridunit-small; }
        &__actions { flex: 0 0 482px; margin-top: 50px; justify-content: space-between; }
      }
      @include mobile {
        &__actions {
          display: block; min-width: 100%;
          &-group { min-width: 100%; flex: 0 0 100%; justify-content: space-between; flex-wrap: wrap; }
        }
        &__prices,
        &__total {
          min-width: 200px;
        }
        &__prices,
        &__quantity { margin: $gridunit 0 0; }
        &__total {
          display: flex; justify-content: space-between; margin-left: 0; margin-top: $gridunit-small;
          SPAN { font-size: $font-size-large; margin-right: $gridunit; }
          P { flex-direction: column; align-items: flex-start; }
          &-prices {
            flex: 0 0 auto; margin-left: 0; display: flex !important; flex-direction: row-reverse; }
        }
        &__remove { margin-top: $gridunit-small; }
        &__add { width: 100% !important; display: flex !important; max-width: none !important; flex: 0 0 100% !important; }
        .product-pricing {
          flex-direction: row-reverse; justify-content: flex-end;
          &__price { margin-right: $gridunit; }
        }
      }
      @include mobile-small {
        &__quantity { float: left; }
      }
    }
    &.compact {
      .box-product {
        &__image { flex: 0 0 80px; box-sizing: border-box; min-height: 0; height: 80px; }
        &__actions { margin-top: 0; }
        &__description { min-height: 0; }
        &.is-selectable .box-product__image {
          margin-left: 40px;
          .control-checkbox { margin-left: -30px; }
        }
      }
    }
    &.tablet-compact {
      @include tablet {
        .box-product {
          &__image { flex: 0 0 80px; box-sizing: border-box; min-height: 0; height: 80px; }
          &__actions { margin-top: 0; }
          &__description { min-height: 0; }
          &.is-selectable .box-product__image {
            margin-left: 40px;
            .control-checkbox { margin-left: -30px; }
          }
        }
      }
    }
  }
  .gift-product {
    .box-product {
      @include not-mobile {
        width: 100%;
        &__main { flex-direction: row; flex: 0 0 100%; align-items: center; }
        &__content { justify-content: center; }
        &__text { flex: 0 0 auto; }
        &__description { min-height: 0; }
        &__image { flex: 0 0 100px; margin-right: $gridunit; min-height: 150px; align-self: stretch; }
      }
      @include mobile {
        &__add { width: 100%; max-width: none; }
      }
      &__add {
        position: relative; z-index: 4; min-width: 8em;
        svg {
          margin-left: $gridunit-small/2;
          path { fill: currentColor !important; }
        }
      }
      &__remove {
        position: relative; border: 1px solid $brand-gray-placeholder-light; background-color: $brand-gray-300; margin-left: $gridunit-large; z-index: 4;
        text-indent: -10000px; width: 38px; height: 38px; box-sizing: border-box; cursor: pointer; border-radius: $base-radius;
        &:hover { background-color: $brand-gray-350; }
        &:focus { box-shadow: $shadow-focus2; }
        IMG { position: absolute; left: 0; right: 0; top: 0; bottom: 0; width: $gridunit; height: $gridunit; margin: auto; }
      }
    }
  }
}

.cart-actions {
  display: flex; justify-content: space-between; flex-wrap: wrap;
  &__primary,
  &__secondary { flex: 0 0 auto; max-width: 100%; }
  &__primary { display: flex; }
  @include mobile {
    &__primary,
    &__secondary {
      flex: 0 0 100%;
      .buttons.layout-default .button { width: auto; display: inline-flex; }
    }
  }
}

.cart-block {
  border-radius: $base-radius; margin: $gridunit 0 0; line-height: 1.5;
  P { margin: 0; font-weight: $medium-bold; }
  &.theme01 {
    background-color: $brand-gray-200; padding: $gridunit-small;
    A, .text-accented { color: darken($brand-primary-800-wcag, 5%) }
  }
  &.theme02 {
    background-color: $brand-accent-100; padding: $gridunit-small;
    A, .text-accented { color: darken($brand-primary-800-wcag, 5%) }
  }
  &.theme03 {
    background-color: $color-white; border: 1px solid $brand-gray-300; padding: $gridunit-small;
    A, .text-accented { color: darken($brand-primary-800-wcag, 5%) }
    P {
      font-weight: $normal;
      &.bold { font-weight: $medium-bold; }
      &.meta { font-size: $font-size-small; padding: $gridunit 0; }
      .text-accented { font-weight: $medium-bold; }
    }
    .layout-cart__main & {
      background-color: $brand-gray-100; border-color: $brand-gray-350; border-width: 2px;
      P {
        &.bold {
          display: flex; align-items: center;
          &.center { justify-content: center; }
          IMG { margin-right: $gridunit-small; }
        }
        &.meta {
          font-size: $font-size-medium;
          @include mobile {
            padding: $gridunit-small*0.7 0;
          }
        }
      }
      @include desktop {
        padding: $gridunit-large; font-size: $font-size-medium;
        P {
          &.bold {
            font-size: $font-size-large;
            IMG { width: $gridunit-large; height: $gridunit-large; }
          }
          &.meta { font-size: $font-size-medium; }
        }
      }
    }
  }
  FORM {
    margin: $gridunit 0 0;
    .form-control { width: 100%; }
    .button { margin: $gridunit-small 0 0; }
  }
  LEGEND {font-weight: $normal !important; }
  UL.checkboxes {
    margin: 0 !important;
    > LI { margin-top: 0; }
  }
  .inline .system-notification {
    padding: $gridunit-small;
    &__icon { margin-left: 0; margin-right: $gridunit-small; }
  }
  .cart-button { padding: 0 $gridunit; }
}

.cart-prices {
  margin: $base-margin; line-height: 1.4;
  > LI { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; justify-content: space-between; margin: $gridunit-small 0 0; }
  .primary {
    font-weight: $bold; padding-bottom: $gridunit-small;
    &.separated { padding-bottom: 0; }
  }
  .separated { border-top: 1px solid $brand-gray-300; margin: $gridunit 0 0; padding-top: $gridunit; }
  .label { flex: 1 1 auto; }
  .value { flex: 0 0 auto; padding-left: 8px; font-weight: $bold; }
  IMG { height: 16px; margin-right: $gridunit-small/2; }
}

