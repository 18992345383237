.benefits-list {
  display: flex; flex-wrap: wrap; margin: $gridunit $gridunit-large/-2 0; line-height: 1.4;
  > LI { flex: 1; margin: 0 $gridunit-large/2; background: $brand-gray-200; padding: $gridunit; border-radius: $base-radius; }
  P { margin: 0; display: flex; flex-wrap: wrap; position: relative; padding-right: 30px; }
  STRONG { display: block; flex: 0 0 100%; font-size: $font-size-largest; order: 3; }
  .benefit-tooltip {
    position: absolute; right: 0; top: 3px; cursor: pointer;
    &__toggle {
      &:hover {
        + SPAN { display: block; }
      }
    }
    &__content {
      display: none; z-index: 10; position: absolute; right: 0; top: 100%; width: 200px; background: $color-white; padding: $gridunit; border-radius: $base-radius; box-shadow: $shadow-menus;
    }
  }
  @include mobile {
    flex-direction: column; margin: $gridunit 0 0;
    > LI { margin: $gridunit-small 0 0; }
  }
}
