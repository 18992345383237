@import "../variables";
@import "../mixins";

@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";
@import "~@blueprintjs/core/src/common/react-transition";
@import "~@blueprintjs/core/src/components/popover/common";

.popover-default-portal {
	position: absolute; left: 0; right: 0; top: 0; z-index: $modalZ + $pt-z-index-overlay;
	$trans: (opacity: (0, 1), transform: (translateY(-5px), translateY(0)));
	@include react-transition("#{$ns}-popover", $trans, $uispeed, $uieasing, $before: "& ", $after: " > *");
	.#{$ns}-popover-enter-active { pointer-events: none; }

	&.above-menu { z-index: 999999; }
}

.popover-default-popover {
	display: inline-block; z-index: $modalZ + $pt-z-index-overlay; border-radius: $base-radius;

	@include popover-sizing($arrow-square-size: 20px, $arrow-offset: 4px, $arrow-target-offset: -4px);
	@include popover-appearance($color-white, inherit, $shadow01, 1, 0);

	.#{$ns}-popover-content {
		background-color: $color-white; padding: 10px 20px; box-sizing: border-box; border-radius: $base-radius; box-shadow: $shadow01;
		&:focus { outline: none; }
		*:focus { outline: none; }
	}
	.#{$ns}-transition-container {
		display: flex; z-index: $modalZ + $pt-z-index-overlay;
		&:focus { outline: none; }
		&.#{$ns}-popover-leave .#{$ns}-popover-content { pointer-events: none; }
		&[data-x-out-of-boundaries] { display: none; }
	}
	&:focus { outline: none; }

	&.nopadding .#{$ns}-popover-content { padding: 0; }

	.#{$ns}-datepicker { padding: 20px; }
}
