// Product box

.box-product {
  background-color: $color-white;
  position: relative; z-index: 0; font-size: $base-font-size; line-height: 1.5; box-sizing: border-box;
  flex: 1 0 auto; display: flex; flex-direction: column; padding: $gridunit-small; width: 276px;
  .react-multi-carousel-list & { margin: 0 auto; width: 100%; max-width: 276px; }
  .horizontal & {
    width: 100%; margin-top: $gridunit-large*2;
    @include not-mobile {
      min-height: 160px;
    }
  }
  &.special {
    .light &,
    & {
      .product-pricing__price-perunit,
      .box-product__description-secondary { color: $color-text-dark; }
    }
    .box-product__image {
      SPAN { width: 200px; height: 200px; border-radius: 50%; background-size: cover;}
    }
  }
  &.selectable {
    cursor: pointer; margin-left: 0; margin-right: 0; flex-wrap: nowrap;
    A { display: none; }
  }

  &:hover &__title { color: $brand-accent-wcag; }
  &__main {
    position: relative; flex: 1 1 auto; display: flex; flex-direction: column;
    .horizontal & {
      @include not-mobile {
        padding-left: 180px; position: relative; flex: 0 1 100%;
      }
    }
  }
  &__actions {
    flex: 0 0 auto;
    .button {
      display: block; margin: $gridunit-small 0 0; width: 100%;
      &.button-discrete {
        text-decoration: none; color: $brand-primary-800; font-size: $font-size-medium-small;
        SPAN {
          display: flex; justify-content: center; align-items: center;
          &.icon { width: 32px; height: 32px; border-radius: 50%; background-color: $brand-gray-100; }
        }
      }
    }
    .horizontal & {
      @include not-mobile {
        margin-left: 160px + $gridunit;
        .button { width: auto; }
      }
    }
  }
  &__cart {
    position: absolute; z-index: 4; left: 0; right: 0; top: 0; background: $color-system-success-200; padding: $gridunit; color: $color-system-success-800; text-align: center; font-weight: $bold;
    IMG {
      margin-right: $gridunit-small/2;
      .carousel & { width: auto; }
    }
    A {
      display: block; color: $color-system-info-1000;
      .visible-focus &:focus { box-shadow: $shadow-focus; }
    }
    .horizontal & { position: static; margin-top: $gridunit; }
  }
  &__link {
    position: absolute; left: 0; right: 0; top: 0; bottom: 0; color: rgba(0, 0, 0, 0) !important; z-index: 3;
    &:focus {
      .visible-focus & { box-shadow: $shadow-focus2; }
      ~ .box-product__content .box-product__title { text-decoration: underline; }
    }
  }
  &__image {
    position: relative; padding: 0; display: flex; flex-wrap: wrap; min-height: 202px; justify-content: space-between;
    SPAN,
    SPAN > IMG, { position: absolute; margin: auto; left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; background-repeat: no-repeat; background-position: 50% 50%; background-size: contain; }
    SPAN > IMG {
      object-fit: contain;
      .carousel.carousel-inline & { width: 100%; height: 100%; }
    }
    .control-checkbox { z-index: 4; left: 5px; top: 5px;}
    .horizontal & {
      @include not-mobile {
        position: absolute; left: 0; top: 0; width: 160px; min-height: 160px; max-height: 160px; margin-right: $gridunit;
        SPAN,
        SPAN > IMG { width: 100% !important; height: 100% !important; }
      }
    }
  }
  &__badges {
    position: relative; z-index: 2; display: flex; flex-direction: column; align-items: flex-start;
    .horizontal & {
      @include not-mobile {
        &-circle { flex: 0 0 100%; align-items: flex-end; }
        &-square { display: none; }
      }
    }
  }
  &__badge {
    &-square {
      margin: $gridunit-small/2 0 0; font-weight: $bold; padding: 0 $gridunit-small/2; background: $brand-badge02-background; color: $brand-badge02-text; border-radius: $base-radius; font-size: $font-size-small;
      IMG {
        margin-right: $gridunit-small/2;
        .carousel & { width: auto; }
      }
      &.theme01 { background: $brand-badge01-background; color: $brand-badge01-text; }
      &.theme02 { background: $brand-badge02-background; color: $brand-badge02-text; }
      &.theme03 { background: $brand-badge03-background; color: $brand-badge03-text; }
    }
    &-circle {
      margin: $gridunit-small/2 0 0; width: 4em; min-height: 4em; font-weight: $bold; font-size: $font-size-small; display: flex; align-items: center; justify-content: center; padding: $gridunit-small/2; border-radius: 3em;
      &.theme01 { background: $brand-badge03-background; color: $brand-badge03-text; }
      &.theme02 { background: $brand-badge04-background; color: $brand-badge04-text; }
      &.theme03 { background: $brand-badge02-background; color: $brand-badge02-text; }
      .horizontal & {
        @include not-mobile {
          width: 3em; min-height: 3em;
        }
      }
    }
  }
  &__content { flex: 1 1 auto; display: flex; flex-direction: column; overflow: hidden; }
  &__text { flex: 1 0 auto; display: flex; flex-direction: column; }


  &__title {
    margin-top: 10px; text-transform: uppercase; max-height: 6em; overflow: hidden; flex: 1 0 auto;
    .horizontal & { flex: 0 0 auto; }
  }
  &__text { font-size: $font-size-small; }
  &__description {
    font-size: $font-size-small; position: relative; padding-left: $gridunit-large; text-align: left; margin: 1.5em 0 0;
    min-height: 4.5em;
    .horizontal & { min-height: 0; }
    .center & { text-align: center; }
    P { margin: 0; }
    &-icon {
      position: absolute; left: 0; top: 0; width: $gridunit; height: $gridunit;
      .carousel & { width: auto; }
    }
    &-primary {
      font-weight: $bold; letter-spacing: 0.01em;
      SPAN { color: $brand-gray-placeholder; }
      &:first-child { margin-left: -$gridunit-large; }
    }
    &-secondary {
      color: $brand-gray-placeholder; font-style: italic;
      SPAN { display: block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; }
      &:first-child,
      &:nth-child(2) { margin-left: -$gridunit-large; }
    }
    &-tertiary {
      color: $brand-gray-placeholder; margin-top: $gridunit !important;
      SPAN { font-weight: $bold; }
    }
  }
  &__prices {
    margin: 1.5em 0 0;
    .compact & { margin: 0; }
    .layout-products-compact & {
      @include not-mobile { flex: 0 0 7em; }
    }
    .product-pricing {
      .center & { justify-content: center; }
    }
    + .button { margin-top: 3.7em; }
  }
  &__quantity {
    display: flex; justify-content: flex-start; margin: $gridunit 0 0; min-height: 2.3em; align-items: center;
    .loading-content { align-self: center; }
    LABEL {
      margin-right: $gridunit-small; font-size: $font-size-medium-small;
      + INPUT { width: 80px; text-align: center; font-weight: $medium; }
    }
    BUTTON {
      cursor: pointer; max-width: 34px; overflow: hidden; position: relative; z-index: 1; background-color: $brand-gray-300; border: 1px solid $brand-gray-placeholder-light; text-indent: -10000px; color: transparent; padding: $gridunit-small $gridunit; font-weight: $bold;
      &:before { color: $color-text-dark; }
      &:first-child {
        border-radius: $base-radius 0 0 $base-radius;
        &:before { content: "-"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
      }
      &:last-child {
        border-radius: 0 $base-radius $base-radius 0;
        &:before { content: "+"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
      }
      &:disabled { pointer-events: none; color: transparentize($color-text-dark, .5); }
      &:hover { background-color: $brand-gray-350; }
      &:active { background-color: $brand-gray-350; border-color: $brand-gray-600; }
      &:focus { background-color: $brand-gray-350; box-shadow: $shadow-focus2; }
    }
    INPUT {
      border: 1px solid $brand-gray-placeholder-light; margin: 0 -1px; flex: 0 0 auto; width: 4rem; padding: $gridunit-small $gridunit-small/2; text-align: center;
      &:focus { box-shadow: 0 0 0 2px $color-white, 0 0 0 4px transparentize($brand-focus-1, .6); position: relative; z-index: 2; }
      &:disabled { pointer-events: none; color: transparentize($color-text-dark, .5); }
    }
  }

  // On gray background

  @at-root .gray & { background-color: $color-white; }

  // Light

  @at-root .light & { background-color: transparent; }

  // List view

  @at-root .list & {
    flex-direction: row; flex-wrap: nowrap; text-align: left; align-items: center; padding-right: 0;
    @include not-desktop { align-items: flex-start; }
    &__image { flex: 0 0 200px; max-width: 200px; height: 200px; padding: 0; margin-right: 20px; }
    &__description { display: block; font-size: 13px; margin-top: 10px; }
    &__pre-title { position: static; transform: none; }
    &__title { margin-top: 0; }
    &__swatches { align-items: flex-start; justify-content: flex-start; }
    &__price {
      margin-top: 10px;
      &.old { margin-top: 0; font-size: 12px; }
    }
    @include mobile {
      &__image { flex: 0 0 70px; max-width: 70px; height: 70px; margin-right: 15px; }
      &__description { font-size: 12px; }
    }
    @include tablet {
      &__image { flex: 0 0 150px; max-width: 150px; height: 150px; }
    }
  }

  @at-root .upsell & {
    max-width: none; width: auto; padding: 0;
    &:hover .box-product__title { color: $color-text-dark; }
    &__actions {
      display: flex; justify-content: space-between; margin: $gridunit $gridunit-small/-2 0; flex-wrap: wrap;
      .button { margin: $gridunit-small/2; flex: 1; min-width: 250px; }
    }
  }

  @include mobile {
    width: 100%; padding: $gridunit-small $gridunit;
    &__title { font-size: $font-size-medium-large; font-weight: $bold; line-height: 1.2; max-height: 1.2*4; }
    &__image { height: 40vw; max-height: 320px; }
    &__description { font-size: $font-size-smaller; }
  }
  @include mobile-small {
    &__image { height: 80vw; max-height: 120px; }
  }
}

.visible-focus .cart-products .box-product__link:focus { box-shadow: $shadow-focus; }
