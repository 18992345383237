TABLE.data01 {
  line-height: 1.3; width: 100%; border-collapse: collapse; margin: $gridunit 0 0;
  TD, TH { vertical-align: top; padding: $gridunit $gridunit-small; word-wrap: break-word; }
  .visible-focus & A:focus { box-shadow: $shadow-focus; }
  TR:not(.noborder) {
    TD,
    TH {
      text-align: left; position: relative;
      @include desktop {
        &:first-child {
          padding-left: $gridunit;
          &:after { border-left-style: solid; }
        }
        &:last-child {
          padding-left: $gridunit;
          &:after { border-right-style: solid; }
        }
        &:after { content: ""; pointer-events: none; position: absolute; left: 0; right: 0; top: 0; bottom: 0; border: 1px transparent; border-style: solid none; }

      }
    }
    &:not(.total) {
      &:nth-child(odd) TD { background-color: #f8f8f8; }
      &:nth-child(even) TD { background-color: $brand-gray-200; }
      TD A { color: darken($brand-primary-800-wcag, 5%); }
      &:hover {
        TD {
          background-color: $color-white;
          &:after { border-color: $brand-gray-400; }
        }
      }
    }
    &.total {
      TD,
      TH {
        border: none; padding: $gridunit-small;
      }
      @include desktop {
        &:first-child {
          TD,
          TH {
            padding-top: $gridunit-large;
          }
        }
      }
    }
  }
  TH {
    font-weight: $bold;
    .button-icon {
      transform: translate(0, .8em);
      .button-icon-inner {
        .button-icon-main {
          background-color: transparent; color: $color-text-dark; border-radius: 50%;
          &:before { left: -1px; top: -1px; right: -1px; bottom: -1px; border-radius: 50%; }
        }
      }
      &:hover {
        .button-icon-inner {
          .button-icon-main {
            background-color: $brand-gray-100;

          }
        }
      }
    }
    .button:not(:first-child) { margin-left: $gridunit; }
  }
  TD {
    height: 1px;
    > *:first-child { margin-top: 0; }
    &.actions {
      A { margin-right: $gridunit; }
    }
    .img-left { float: left; margin-right: $gridunit-small; }
  }
  SMALL { opacity: 0.8; }
  &.grouped {
    TBODY {
      &:nth-child(odd) TR:not(.total) TD { background-color: #f8f8f8; }
      &:nth-child(even) TR:not(.total) TD { background-color: $brand-gray-200; }
    }
    IMG { max-height: 25px; width: auto; }
  }
  TR.valign-center {
    TD, TH { vertical-align: middle; }
  }
 @include not-desktop {
   &:after { display: none; }
   &.responsive {
      display: block;
      THEAD,
      TBODY,
      TFOOT,
      TR,
      TD,
      TH { display: block; height: auto; }

      TH { display: none; }
      TR {
        &.total {
          display: flex; justify-content: space-between;
          TD {
            padding: $gridunit-small/2 $gridunit; text-align: right;
            &:first-child {
              flex: 0 0 55%;
              &:last-child { flex: 0 0 100%; box-sizing: border-box; }
            }
          }
          &:first-of-type { margin-top: $gridunit; }
        }
      }
      TD {
        word-wrap: break-word;
        padding: $gridunit-small/2 $gridunit;
        &:first-child { padding-top: $gridunit; }
        &:last-child { padding-bottom: $gridunit; }
        &[aria-label] {
          display: flex;
          &:before { content: attr(aria-label) ":"; text-align: left; flex: 0 0 35%; font-weight: $bold; margin-right: $gridunit-small; max-width: 35%; word-wrap: break-word; }
          &.actions .button { margin: 0; }
        }
        &.responsive-hide { display: none; }
        &.w1p { width: auto; @include clearfix; }
        .img-left { display: block; width: auto; height: auto; max-width: 150px; margin: 0 auto; float: none; }
      }
    }
  }
}

.table-tools {
  display: flex; justify-content: space-between; margin: $gridunit 0 0;
  &__amount { margin: $gridunit 0 0; }
  &__per-page {
    margin: $gridunit-small 0 0;
    SELECT { width: 80px; margin: 0 $gridunit; }
  }
  @include mobile {
    flex-wrap: wrap;
    .paging { order: 3; flex: 0 0 100%; margin: $gridunit 0 0; }
  }
}

.table-filters {
  display: flex; flex-wrap: wrap; background: $brand-gray-200; border-radius: $base-radius; padding: $gridunit-small $gridunit $gridunit; margin: $gridunit 0 0;
  LABEL {
    margin: $gridunit-small $gridunit 0 0; font-weight: $bold;
    @include mobile { flex: 0 0 100%; }
  }
  > * { flex: 0 0 auto; }
  SELECT {
    font-weight: $normal;
    @include not-mobile { width: 399px; }
    @include tablet { width: 250px; }
    @include mobile { width: 100%; margin: $gridunit-small/2 0 0; }
  }
  BUTTON { min-width: 150px; margin: $gridunit-small 0 0; }
  @include mobile {
    BUTTON { margin-top: $gridunit; width: 100%; }
  }
}

.paging {
  UL { display: flex; padding: 0 !important; }
  LI {
    padding: 0 !important; margin: 0 $gridunit-small !important;
    &:before { display: none; }
  }
  &-item {
    A {
      display: block; width: 2em; height: 2em; border-radius: 50%; border: 1px solid transparent; color: $color-text-dark; line-height: 2em; text-align: center; margin: .5em 0;
      &.current,
      &:hover,
      &:active { background: $color-system-info-200; border: 1px solid $color-system-info-500; text-decoration: none; }
      &:focus { box-shadow: $shadow-focus; }
    }
    &__next,
    &__previous {
      A {
        margin: 0; width: 3em; height: 3em; line-height: 3em; text-indent: -10000px; position: relative; border: 1px solid $brand-gray-placeholder-light;
        IMG { width: 9px; height: 15px; position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto; }
        &:hover,
        &:active { background: $brand-gray-200; }
      }
      &.disabled { opacity: 0.5; pointer-events: none; }
    }
  }
}
