// Accordions

.list-accordions {
  margin: 50px 0 0 0; border-bottom: 1px solid $color-gray-light;

  &__item {
    border-top: 1px solid $color-gray-light; overflow: hidden; position: relative; z-index: 0;

    > INPUT[type="checkbox"] {
      position: absolute; left: -10000px;
      + * + * { border-bottom-color: transparent; transform: translateY(-100px); pointer-events: none; position: absolute; left: 0; top: 100%; width: 100%; z-index: -1; margin: 0; transition: all $uispeed $uieasing 0ms; display: block; }
      + * LABEL {
        &:before { content: ""; width:  $gridunit-small*1.5; height:  $gridunit-small*1.5; box-sizing: border-box; position: absolute; left: $gridunit; top: $gridunit-small*2; transform: rotate(135deg); border: 3px $color-text-dark; border-style: solid solid none none; }
        &:hover,
        &:active {
          background: $color-system-info-200;
          &:before { transform: rotate(315deg); top: 20px; }
        }
        &:focus { box-shadow: $shadow-focus inset; }
      }
      &:focus + * LABEL { box-shadow: $shadow-focus inset; }
      &:checked {
        ~ LABEL,
        + * LABEL {
          border-bottom: 1px solid $brand-gray-350;
          &:before { transform: rotate(315deg); top: 20px; }
          &:hover,
          &:active {
            background: $color-system-info-250;
            &:before { transform: rotate(135deg); top: $gridunit-small*2; }
          }
        }
        + LABEL ~ *,
        + * ~ *,
        + * + * { display: block; transform: none; pointer-events: auto; position: static; z-index: 0; }
      }
    }

    &__heading {
      position: relative; cursor: pointer; background: $color-white; z-index: 1;
      margin: 0; text-transform: uppercase; font-size: $base-font-size; font-weight: $bold;
      A,
      LABEL {
        border-bottom: 1px solid transparent; display: block; padding: $gridunit $gridunit*2 $gridunit $gridunit*3; cursor: pointer; line-height: inherit; color: inherit;
        &:hover { color: $color-link; text-decoration: none; }
      }

    }
    &__content {
      line-height: 1.5; padding: $gridunit $gridunit $gridunit $gridunit*3; border-bottom: 1px solid $brand-gray-350; box-sizing: border-box;
      > *:first-child { margin: 0; }
    }
  }
}
