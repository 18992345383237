@import 'variables';
@import 'mixins';

.center { text-align: center !important; }
.right { text-align: right !important; }
.left { text-align: left !important; }
.nowrap { white-space: nowrap !important; }
.nomargin { margin: 0; }
.sum { text-align: right !important; white-space: nowrap !important; }
.bold { font-weight: $bold; }
.blur { filter: blur(5px); }
.hidden { display: none !important; }

.font-size-small { font-size: $font-size-small; }
.font-size-smaller { font-size: $font-size-smaller; }
.font-size-smallest { font-size: $font-size-smallest; }

.clear { @include clearfix; }
.f-right { float: right; }
.f-left { float: left; }

@include mobile { .mobile-hide { display: none !important; } }
@include not-mobile { .not-mobile-hide { display: none !important; } }
@include tablet { .tablet-hide { display: none !important; } }
@include desktop { .desktop-hide { display: none !important; } }
@include not-desktop { .not-desktop-hide { display: none !important; } }

.iframe { @include defaultMargin(); border-radius: $base-radius; overflow: hidden; background-color: rgba(0, 0, 0, 0.05); }

// Widths

.wauto { width: auto !important; flex: 0 0 auto; }

.w1p { width: 1%; }
.w10p { width: 10%; }
.w20p { width: 20%; }
.w25p { width: 25%; }
.w30p { width: 30%; }
.w40p { width: 40%; }
.w50p { width: 50%; }
.w60p { width: 60%; }
.w70p { width: 70%; }
.w75p { width: 75%; }
.w80p { width: 80%; }
.w90p { width: 90%; }
.w100p { width: 100%; }

@include mobile {
	.mobile-w1p { width: 1%; }
	.mobile-w10p { width: 10%; }
	.mobile-w20p { width: 20%; }
	.mobile-w25p { width: 25%; }
	.mobile-w30p { width: 30%; }
	.mobile-w40p { width: 40%; }
	.mobile-w50p { width: 50%; }
	.mobile-w60p { width: 60%; }
	.mobile-w70p { width: 70%; }
	.mobile-w75p { width: 75%; }
	.mobile-w80p { width: 80%; }
	.mobile-w90p { width: 90%; }
	.mobile-w100p { width: 100%; }
}
@include desktop {
	.desktop-w1p { width: 1%; }
	.desktop-w10p { width: 10%; }
	.desktop-w15p { width: 15%; }
	.desktop-w20p { width: 20%; }
	.desktop-w25p { width: 25%; }
	.desktop-w30p { width: 30%; }
	.desktop-w40p { width: 40%; }
	.desktop-w50p { width: 50%; }
	.desktop-w60p { width: 60%; }
	.desktop-w70p { width: 70%; }
	.desktop-w75p { width: 75%; }
	.desktop-w80p { width: 80%; }
	.desktop-w90p { width: 90%; }
	.desktop-w100p { width: 100%; }
}

.text-highlight { background: rgba(red, 0.8); box-shadow: 0 0 0 3px rgba(red, 0.8); }

HR { height: 1px; overflow: hidden; border: none; background: $color-gray-light; margin: 0; @include defaultMargin(); }

.todo {
	opacity: .1 !important; filter: blur(5px); pointer-events: none;
}

@each $intent, $color in $intents {
	.text-intent-#{$intent} {
		color: $color;
	}
}

.animation-spin { animation: animation-spin $uispeed * 4 infinite linear; }
.animation-fade-in {
	animation-name: animation-fade-in;
	animation-delay: 0ms;
	animation-iteration-count: 1;
	animation-duration: $uispeed * 2;
	animation-timing-function: linear;
}

@keyframes animation-spin {
	to {
		transform: rotate(360deg);
	}
}
@keyframes animation-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes bounce-in {
	0% {
		opacity: 0;
		transform: scale3d(.1, .1, .1);
	}

	20% {
		transform: scale3d(1.5, 1.5, 1.5);
	}

	40% {
		transform: scale3d(.9, .9, .9);
	}

	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}

	80% {
		transform: scale3d(.97, .97, .97);
	}

	to {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}
