@import "../variables";
@import "../mixins";

@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/mixins";

@import "~@blueprintjs/core/src/common/variables";

$date-size: 30px;

.rdp {
	display: inline-block;
	&:focus { outline: none; }

	&-wrapper {
		position: relative;
		&:focus { outline: none; }
	}
	&-navbar {
		position: absolute; left: 0; right: 0; z-index: 1;
		.rdp-NavButton {
			position: absolute; top: 0; width: $date-size; height: $date-size; background: transparent; border: none; padding: 0;
			&[disabled] { opacity: 0.2; }
			path { fill: currentColor; }
		}
		.rdp-NavButton--prev { left: 0; }
		.rdp-NavButton--next { right: 0; }
	}
	&-table { font-size: $font-size-small; border-collapse: collapse; }
	&-months { display: flex; flex-direction: row; }
	&-month {
		max-width: 240px; position: relative;
		.rdp-caption {
			padding: 0 $date-size; text-align: center; margin-bottom: 10px; min-height: $date-size; line-height: $date-size; pointer-events: none;
			text-transform: uppercase; letter-spacing: 1px; font-weight: $bold; color: $color-text-dark; position: relative;
			&_dropdowns {
				display: flex; padding: $gridunit-small/2; gap: $gridunit-small/2; position: relative; z-index: 2;
				> DIV {
					font-size: $font-size-small;
					&.rdp-vhidden { display: none; }
					SELECT { font-size: $font-size-small; }
				}
			}
			.rdp-nav {
				position: absolute; left: $gridunit-small/2; right: $gridunit-small/2; top: $gridunit-small/2; bottom: 0; display: flex; justify-content: space-between;
				BUTTON {
					border: none; background-color: transparent; width: 2em; height: 2em; cursor: pointer; border-radius: $base-radius; position: relative;
					&:hover { background-color: $color-gray-light; }
					svg { width: 16px; height: 16px; position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; }
					.#{$ns}-icon { display: flex; width: 100%; justify-content: center; }
				}
			}
			SELECT { text-overflow: ellipsis; text-transform: capitalize; color: inherit; display: inline; appearance: none; padding: 0 10px; margin: 0 5px; background: transparent; border: none; font-weight: $bold; height: $date-size; line-height: $date-size; }
			.#{$ns}-divider { display: none; }
			.#{$ns}-icon { display: none; }
			.#{$ns}-html-select { display: inline; }
			.#{$ns}-datepicker-caption {
				display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: stretch;
				> * { flex: 1 0 100%; }
				SELECT { width: 100%; }
			}
		}
		.rdp-weekdays {}
		.rdp-weekdaysrow {
			display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between;
			.rdp-weekday { color: rgba($color01, 0.5); font-size: 12px; width: $date-size; text-align: center; text-transform: uppercase; letter-spacing: 1px; }
		}
		.rdp-table TH { text-align: center; }
		.rdp-body { min-height: ($date-size * 6) + 12; }
		.rdp-row {
			/*display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between;*/ padding: 0;
			.rdp-day {
				color: $color-link; margin: 1px; border: none; background-color: transparent; font-size: $font-size-small;
				flex: 0 0 auto; width: $date-size; height: $date-size; line-height: $date-size; box-sizing: border-box; overflow: hidden; text-align: center; outline: none; border-radius: $base-radius; cursor: default;
				&:hover { background-color: $color-link; color: $color-white; }
			}
			.rdp-day_outside { opacity: 0.3; }
			.rdp-day_selected { color: $color-white; background-color: $color-active !important; }
			.rdp-day_selected-range { color: $color-white; background-color: rgba($color05, 0.3); }
			.rdp-day_disabled { color: rgba($color-text-dark, 0.3) !important; background-color: transparent !important; }
		}
		ABBR { text-decoration: none; }
	}
}
