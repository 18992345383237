.layout-404 {
  display: flex; align-items: center; flex-direction: column; font-size: $font-size-largest; font-weight: $bold; padding: 0 $gridunit;
  @include mobile { font-size: $font-size-large; }
  H1 {
    font-size: 100px; font-weight: $bold;
    display: flex; justify-content: center; align-items: center;
    color: $brand-primary-800-wcag; background: $brand-gray-200; height: 2.4em; width: 2.4em; border-radius: 50%; margin: $gridunit-large 0 0;
  }
  P { margin: 1em 0 0; line-height: 1.4; text-align: center; }
  A { font-size: $base-font-size; font-weight: $normal; }
}

.layout-error {
  display: flex; align-items: center; flex-direction: column; padding: $gridunit-large*2 $gridunit $gridunit-large; max-width: 800px; margin: 0 auto;

  H1 {
    font-size: $font-size-largest; font-weight: $bold; margin: $gridunit-large*1.5 0 0;
    @include mobile { font-size: $font-size-large; }
  }
  P { margin: 1em 0 0; line-height: 1.4; text-align: center; }
  A { font-size: $base-font-size; font-weight: $normal; }
}
