.services {
  display: flex; justify-content: space-between; flex-wrap: wrap; margin: $gridunit-small $gridunit-large*-1 0;
  &.compact { display: block; }
  &__item {
    flex: 0 0 50%; box-sizing: border-box; padding: $gridunit-small $gridunit-large; line-height: 1.4; max-width: 100%;
    display: flex; flex-direction: column;
    .visible-focus & A:focus { box-shadow: $shadow-focus; }
    &__inner {
      flex: 0 0 100%; box-sizing: border-box; display: flex; background: $brand-gray-200; padding: $gridunit; align-items: flex-start; border-radius: $base-radius;
      .frame-epharma__section.theme06 & { background-color: $color-white; }
      .news & {
        align-items: center; padding: $gridunit-large*2 $gridunit;
        @include mobile {
          align-items: stretch;
        }
      }
      .full.news.reverse & {
        @include not-mobile {
          flex-direction: row-reverse;
        }
      }
      .compact .news & { padding: 0 $gridunit $gridunit; align-items: stretch; }
      .full & { min-height: 400px; box-sizing: border-box; }
    }
    &.full {
      flex: 0 0 100%; max-width: 100%;
      @include mobile {
        display: block;
      }
    }
    @include desktop { max-width: 50%; }
    @include not-desktop { flex: 0 0 100%; }
  }
  &__text {
    flex: 0 1 100%;
    @include not-mobile {
      padding-right: $gridunit;
      .full.news.reverse & { padding-right: 0; padding-left: $gridunit; }
    }
  }
  &__img {
    flex: 0 0 250px; height: 250px; object-fit: cover; object-position: center; overflow: hidden; border-radius: 50%; max-width: 100%;
    @include not-desktop-wide {
      flex: 0 0 175px; max-width: 175px; height: 175px;
      IMG { width: 175px; object-fit: cover; object-position: center; height: 175px; }
    }
  }
  &__img02 {
    background: no-repeat 50% 50%; background-size: cover; margin: $gridunit-large*-2 $gridunit*-1 $gridunit-large*-2 0; flex: 0 0 250px; align-self: stretch;
    IMG {
      width: 250px; object-fit: cover; object-position: center; height: 100%;
      .layout-sidebar__secondary & { width: 100%; }
    }
    @include mobile {
      margin-bottom: 0; margin-left: $gridunit*-1;
      .news & {
        min-height: 0; flex: 0 0 auto;
        IMG { width: 100%; height: auto; min-height: 200px; }
      }
    }
    .full.news & {
      flex: 0 0 60%;
      IMG { width: 100%; }
      @include mobile { min-height: 200px; }
    }
    .compact & {
      height: 140px; margin: 0;
      IMG { height: 100%; min-height: 0; }
    }
    .full.news.reverse & {
      @include not-mobile { margin: $gridunit-large*-2 0 $gridunit-large*-2 $gridunit*-1; }
    }
  }
  @include mobile {
    &__item__inner { flex-direction: column; }
    &__text { order: 2; margin-top: $gridunit; }
    &__img { align-self: center; }
  }
  &__title { margin: 0; font-size: $font-size-large; font-weight: $bold; }
  &__meta {
    display: flex; flex-wrap: wrap; font-size: $font-size-small;
    &:not(:first-child) { margin: $gridunit-small 0 0; }
    &-item {
      background: $color-white; border-radius: $base-radius; padding: $gridunit-small; margin: $gridunit-small $gridunit-small 0 0; display: flex; align-items: center;
      .layout-hero__header &,
      .checkboxes.elevated & {
        background-color: $brand-gray-200;
        .services__price.accented { color: darken($brand-primary-800-wcag, 5%);}
      }
    }
    .checkboxes.elevated & { flex: 0 0 100%; padding: 0; box-sizing: border-box; }
    P {
      margin-top: 0;
      &:not([class]) { font-weight: $bold; }
    }
    IMG { height: 12px !important; margin-right: $gridunit-small/2; }
  }
  &__price {
    font-size: $font-size-smaller; margin-right: $gridunit;
    &:last-child { margin-right: 0; }
    &.accented {
      color: $brand-primary-800-wcag;
      IMG { max-width: 18px; }
    }
    SPAN {display: block; font-size: $font-size-small; font-weight: $medium-bold; }
  }
  .compact & {
    &__item {
      flex: 0 0 100%; max-width: none;
      &__inner { flex-direction: column; }
      A { text-decoration: underline; font-weight: $medium-bold; color: darken($brand-primary-900, 0%); }
    }
    &__meta { margin: 0 !important; }
    &__title { font-size: $base-font-size; }
    &__text { order: 2; margin-top: $gridunit; }
    &__img { align-self: center; flex: 0 0 135px; height: 135px; max-width: 135px; }

  }
}

.service-summary {
  margin: $gridunit-large 0 0; font-size: $font-size-small;
  display: flex; flex-direction: column;

  H3 { margin: 0; font-weight: $bold; font-size: $base-font-size;  }
  P {
    margin: $gridunit-small 0 0;
    IMG { margin-right: $gridunit-small; }
  }
  &__time {
    display: flex; justify-content: flex-start; flex-wrap: wrap; font-size: $base-font-size;
    SPAN { margin-right: $gridunit; display: flex; justify-content: flex-start; align-items: center; }
  }
  .content-card & {
    height: 100%;
    &__time {
      margin: $gridunit 0 0;
      + P { margin: $gridunit 0 0}
    }
    .buttons { margin: auto $gridunit-small*-1 0; }
    .button { margin: $gridunit 0 0; }
    &.layout-vertical {
      display: flex; flex-direction: column; height: auto;
      .content-card__header { flex: 0 1 100% !important; }
      > * { flex: 0 0 auto; }
    }
  }
}

.posts {
  &__title {
    margin: 0;
    + .posts__list { margin: $gridunit 0 0; }
  }
  @include not-mobile {
    margin: $gridunit 0 0;
    &.pinned {
      .posts__list { justify-content: space-between; }
      .posts__item { flex: 1; min-width: 300px; }
    }
    &__list { justify-content: center; display: flex; flex-wrap: wrap; }
    &__item {
      flex: 0 0 300px; font-size: $font-size-medium; margin: $gridunit-small; font-weight: $medium-bold;
      &-image {
        flex: 0 0 113px; width: 113px; height: 113px;
      }
    }
  }
  @include mobile {
    margin: 0 $gridunit*-1; padding: $gridunit;
    &__item {
      padding-top: $gridunit; font-weight: $medium-bold; font-size: $font-size-medium-small; margin: $gridunit 0 0; border-top: 1px solid transparentize($color-black, 0.75);
      &:first-child { margin: 0; }
      &-image { flex: 0 0 85px; width: 85px; height: 85px; }
    }
  }
  &__item {
    line-height: 1.4;
    A { display: flex; text-decoration: none; align-items: center; color: $color-text-dark; }
    &-image {
      overflow: hidden; border-radius: 50%; margin-right: $gridunit;
      &.full { border-radius: 0; }
      IMG { width: 100%; height: 100%; object-fit: cover; object-position: center; }
    }
  }
}


