@import "../../ePharma2/scss/variables";

$color-black: #131516;
$color-gray-dark: $color-black;
$color-text-dark: $color-black;

$brand-primary-200: #E8CCBC;
$brand-primary-600: #FF9E92;
$brand-primary-800: #EA1A1E;
$brand-primary-800-wcag: #EA1A1E;
$brand-primary-900: #D1191B;

$brand-accent-wcag: $brand-primary-800-wcag;
$brand-accent-100: #D1DDF7;
$brand-accent-200: #B2C4EC;
$brand-accent-1: $brand-accent-200; //#99B7CC;
$brand-accent-2: #A9E1C6;
$brand-accent-2-200: #D4E2DC;
$brand-accent-2-100: #EAEFED;

$brand-gray-200: $color-gray-lighter;
$brand-gray-300: $color-gray-light;
$brand-gray-800: $color-gray-dark;

$brand-campaign-1: #FFCC56;
$brand-campaign-1-tint: #FFF3D9;
$brand-campaign-2: #CBD7AF;
$brand-campaign-2-tint: #EEF0E9;
$brand-campaign-3: #BAA8E1;
$brand-campaign-3-tint: #F5F2FB;
$brand-campaign-4: #55D3EC;
$brand-campaign-4-tint: #E6F2F4;
$brand-campaign-5: #FFB468;
$brand-campaign-5-tint: #FFF3D9;

$brand-badge01-background: $brand-primary-800-wcag;
$brand-badge01-text: $brand-primary-text;
$brand-badge02-background: $brand-gray-200;
$brand-badge02-text: $color-text-dark;
$brand-badge03-background: $brand-accent-1;
$brand-badge03-text: $color-text-dark;
$brand-badge04-background: $brand-primary-800-wcag;
$brand-badge04-text: $brand-primary-text;

$color-background-header-primary: $color-white;
$color-background-header-secondary: $color-background-header-primary;
$color-border-header: $color-gray-lighter;

$color-accent-product: $brand-campaign-4-tint;

$color-background-languages-idle: $color-white;
$color-background-languages-hover: $color-gray-light;
$color-background-languages-active: $color-gray-medium-light;

$color-background-crossportal-hover: transparentize($color-gray-medium-dark, 0.8);
$color-background-crossportal-active: transparentize($color-gray-medium-dark, 0.6);
$color-system-focus: #0F7798;

$color-text-header-primary: $color-text-dark;
$color-text-header-secondary: $color-text-medium;
$color-text-header-highlighted: $brand-primary-800;
$color-header-accent: $brand-accent-wcag;

$color-background-mainmenu-level1-hover: #EFF3FC;
$color-background-mainmenu-level1-active: #EFF3FC;
$color-border-mainmenu-level1-active: $brand-primary-800;
$color-background-mainmenu-level1-selected: $brand-primary-800;
$color-background-mainmenu-level1-selected-hover: $brand-primary-900;
$color-background-mainmenu-level1-selected-active: #BD2325;
$color-text-mainmenu-level1-selected: $color-text-light;

$color-background-mainmenu-level2-hover: #EFF3FC;
$color-background-mainmenu-level2-active: #EFF3FC;
$color-border-mainmenu-level2-active: $brand-primary-800;
$color-background-mainmenu-level2-selected: $brand-primary-800;
$color-background-mainmenu-level2-selected-hover: $brand-primary-900;
$color-background-mainmenu-level2-selected-active: #BD2325;
$color-text-mainmenu-level2-selected: $color-text-light;

$shadow-active: 0 0 0 1px $color-border-mainmenu-level1-active;
$shadow-active2: 0 0 0 1px $color-border-mainmenu-level2-active;

$color-danger: $brand-primary-800-wcag;

$color-link: $brand-primary-800-wcag;

$color-system-info-200: #EFF3FC;
$color-system-info-250: #D1DDF7;
$color-system-info-350: #B2C4EC;
$color-system-info-500: #79A1FC;
$color-system-info-1000: #4A6CAF;

