.account-items {
  list-style: none; padding: 0 $gridunit; margin: $gridunit 0 0; border: 1px solid $brand-gray-300; border-radius: $base-radius; line-height: 1.5;
  max-width: 768px;
  > LI {
    display: flex; justify-content: space-between; align-items: center; padding: $gridunit 0; margin: 0; border-bottom: 1px solid $brand-gray-300;
    &:last-child { border-bottom: none; };
    &.hasico {
      position: relative; padding-left: $gridunit-large*2+$gridunit; min-height: 45px;
      .icon { position: absolute; left: 0; top: 0; bottom: 0; margin: auto; width: $gridunit-large*2; height: $gridunit-large*2; content: ""; background: no-repeat 50% 50%; background-size: contain; }
    }
    DIV.a {
      font-size: $font-size-small; font-weight: $normal;
      .title { font-size: $base-font-size; margin: 0; font-weight: $bold; }
      .subtitle {
        font-size: $base-font-size; margin: 0; font-weight: $normal;
        &.hilited { color: $brand-primary-800-wcag; }
      }
    }
    @include mobile {
      flex-direction: column; align-items: stretch;
      DIV.b { margin: $gridunit 0 0; }
    }
  }
  &.separated {
    border: none; padding: 0; margin: 0;
    > LI { border: 1px solid $brand-gray-300; padding: $gridunit; margin: $gridunit 0 0; border-radius: $base-radius; }
  }
}

.xy-campaign {
  display: flex; list-style: none; padding: 0; margin: 0;
  LI {
    width: 64px; height: 64px; position: relative; padding: 0;
    &:before { text-indent: 0; position: absolute; right: -23px; top: 0; bottom: 0; margin: auto; height: 30px; line-height: 30px; font-size: 22px; font-weight: $bold; }
    &.stamp {
      border: 1px solid $brand-gray-400; border-radius: $base-radius; background: $color-white; text-indent: -10000px; margin: 0 30px 0 0;
      &.active { border-color: $brand-primary-800; background: $color-white no-repeat 50% 50%; background-size: 35px auto; }
      IMG { position: absolute; left: 0; right: 0; top: 0; bottom: 0; margin: auto; width: 40px; height: 40px;}
      &:before { content: "+"; }
      &:nth-last-child(2):before { content: "="; color: $color-text-dark; right: -25px; }
    }
    &.total {
      border: 1px solid $brand-primary-800; border-radius: $base-radius; color: $brand-primary-800; text-align: center; font-size: $base-font-size; font-weight: $normal; line-height: 1; display: flex; flex-direction: column; justify-content: center;
      STRONG { display: block; }
      &.active { background: $brand-primary-800; color: $color-text-light; }
    }
  }
  @include mobile {
    flex-wrap: wrap; justify-content: flex-start; margin: 0 auto;
    LI {
      width: 46px; height: 46px;
      &.stamp {
        margin: $gridunit-small/2 20px 0 0;
        &:before { right: -17px; }
        &:nth-last-child(2):before { right: -19px; }
        IMG { width: 34px; height: 34px; }
      }
      &.total {
        margin: $gridunit-small/2 0 0 0; font-size: $font-size-small;
      }
    }
  }
}
