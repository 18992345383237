BODY { font-size: $base-font-size; line-height: $base-line-height; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

H1, H2, H3, H4, H5, H6 {
	line-height: 1.2; font-weight: 400; margin: 60px 0 0 0; padding: 0;
	+ HR,
	+ .longpage { margin-top: 16px; }
	@include mobile {
		.not-mobile-hide & { margin-top: 40px; }
	}
}

P { margin: $base-margin; }

H1 {
	font-size: $font-size-largest; font-weight: $bold;
	SMALL { font-weight: $normal; position: relative; top: -1.2em; }
}
H2 {
	font-size: $font-size-larger; font-weight: $medium;
	&.heading-large { font-size: $font-size-larger04; font-weight: $bold; }
	&.heading-small { font-size: $base-font-size; font-weight: $bold; margin: $gridunit-large 0 0 !important; }
}
H3 {
	font-size: $font-size-large; font-weight: $medium;
	&.heading-large { font-weight: $bold; margin: $gridunit 0 0; }
}
H4 { font-size: $font-size-medium; font-weight: $bold; }
H5 { font-size: $font-size-medium; font-weight: normal; }
H6 { font-size: $font-size-small; font-weight: $bold; }

@include mobile {
	H1 { font-size: 18px; font-weight: 600; }
}

* { font-variant-numeric: lining-nums !important; }

.is-fs-smallest { font-size: $font-size-smallest; }
.is-fs-smaller { font-size: $font-size-smaller; }
.is-fs-small { font-size: $font-size-small; }
.is-fs-default { font-size: $base-font-size; }
.is-fs-medium { font-size: $font-size-medium; }
.is-fs-large { font-size: $font-size-large; }
.is-fs-larger { font-size: $font-size-larger; }
.is-fs-largest { font-size: $font-size-largest; }

.text-muted { color: $brand-gray-placeholder; }
.text-highlighted { color: $brand-primary-800-wcag; }
.text-danger { color: $color-system-danger-800; }
