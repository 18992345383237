// Product image

.product-image {
	@include defaultMargin();
	.gallery {
		@include desktop-narrow {
			&,
			&.has-thumbs { padding: 380px 0 0; }
			.carousel:not(:first-child) { display: none; }
		}
		@include not-desktop {
			&,
			&.has-thumbs { padding: 380px 0 0; }
			.carousel:not(:first-child) { display: none; }
		}
		.slider {
			background-color: transparent;
			.slide { background-color: transparent; }
			BUTTON {
				display: block; width: 100%; height: 100%; border-radius: $base-radius; border: none; background-color: transparent;
				.gallery__slide { left: 3px; top: 3px; right: 3px; bottom: 3px; }
				&:focus {
					box-shadow: $shadow-focus-inset; outline: none;

				}
			}
		}
	}
}

// Product badges

.product-badges {
	position: absolute; left: 0; top: 0; right: 0; z-index: 1; display: flex; justify-content: space-between;
	&__group { display: flex; flex-direction: column; align-items: flex-start; }
	&__badge {
		&-square {
			margin: $gridunit-small/2 0 0; font-weight: $bold; padding: $gridunit-small/2 $gridunit-small; background: $brand-badge02-background; color: $brand-badge02-text; border-radius: $base-radius; font-size: $base-font-size;
			IMG { margin-right: $gridunit-small; }
			&.theme01 { background: $brand-badge01-background; color: $brand-badge01-text; }
			&.theme02 { background: $brand-badge02-background; color: $brand-badge02-text; }
			&.theme03 { background: $brand-badge03-background; color: $brand-badge03-text; }
		}
		&-circle {
			margin: $gridunit-small/2 0 0; width: 4em; min-height: 4em; font-weight: $bold; font-size: $base-font-size; display: flex; align-items: center; justify-content: center; padding: $gridunit-small/2; border-radius: 3em;
			&.theme01 { background: $brand-badge03-background; color: $brand-badge03-text; }
			&.theme02 { background: $brand-badge04-background; color: $brand-badge04-text; }
		}
	}
}

// Product rating

.product-rating {
	white-space: nowrap; background: $brand-gray-200; border-radius: $base-radius; padding: $gridunit-small/2 $gridunit-small; color: $brand-accent-wcag; font-size: $base-font-size; font-weight: $normal; position: relative; top: -.4em;
	IMG { margin-right: $gridunit-small; }
	A {
		color: darken($brand-primary-800-wcag, 5%);
		.visible-focus &:focus { box-shadow: $shadow-focus; }
	}
	.visible-focus &:focus { box-shadow: $shadow-focus; }
}
// Product meta
.product-meta { }

// Product configurator

.product-configurator {
	display: flex; flex-wrap: wrap; margin: $gridunit-small $gridunit-small * -1 0;
	&__param {
		flex: 1; padding: 0 $gridunit-small; margin: $gridunit 0 0; min-width: 150px; box-sizing: border-box; display: flex; flex-direction: column;
		&.wide { flex: 0 0 100%; }
		&-title { display: block; font-weight: $medium-bold; flex: 0 1 100%; margin: 0; line-height: 1.2; }
		.form-control { margin: $gridunit-small/2 0 0; width: 100%; }
	}
	&__options {
		list-style: none; display: flex; flex-wrap: wrap; margin: 0;
		> LI {
			margin: $gridunit-small/2 $gridunit-small 0 0;
			&.selected {
				INPUT + SPAN { background-color: $brand-accent-200; border-color: $brand-accent-200; }
			}
			&.disabled {
				INPUT {
					+ SPAN { opacity: 0.5 }
				}
			}
		}
		LABEL {
			position: relative; cursor: pointer; display: block;
			&:hover,
			&:active {
				INPUT:not(:disabled) + SPAN { border-color: $brand-gray-placeholder; }
			}
			INPUT {
				position: absolute; left: -10000px;
				&:focus + SPAN { box-shadow: $shadow-focus; }
				&:checked + SPAN { background-color: $brand-accent-200; border-color: $brand-accent-200; }
				&:disabled + SPAN { pointer-events: none; color: $brand-gray-400; border-color: $brand-gray-350; }
			}
			SPAN { display: block; border: 1px solid $brand-gray-400; border-radius: $base-radius; padding: $gridunit-small $gridunit-small*1.5; }
		}

	}
}

// Product pricing

.product-pricing {
	display: flex; flex-wrap: wrap; margin: $gridunit 0 0; padding: $gridunit 0;
	.box-product & { margin: 0; padding: 0; }
	&__price {
		margin-right: $gridunit-small; text-align: left; color: $brand-gray-placeholder;
		P {
			margin: 0;
			&:first-child:last-child { padding-bottom: 1em; }
		}
		&.special { color: $brand-accent-wcag; }
		&.discount {
			.product-pricing__price-number {
				font-weight: $normal;
				&:before { content: ""; position: absolute; z-index: 1; top: 50%; left: -2%; width: 110%; height: 1px; background: $brand-gray-600; transform:rotate(-17deg); }
			}
			.product-pricing__price-currency { font-size: $font-size-smaller; font-weight: $extra-bold; }
		}
		&-perunit { font-size: $font-size-smaller; color: $brand-gray-placeholder; margin: $gridunit-small 0 0; }
		&-label {
			min-height: 14px; display: flex; align-items: center;
			IMG { width: 14px; height: 14px; }
		}
		&-label,
		&-prefix { margin: 0; font-size: $font-size-smallest; letter-spacing: 0.01em; font-weight: $bold; }
		&-value {
			display: flex; justify-content: flex-start; align-self: flex-end; align-items: flex-end; line-height: 1;
			IMG { width: 18px; height: 18px; }
			&:first-child { margin-top: 1.2em; }
		}
		&-number {
			font-weight: $medium-bold; font-size: $font-size-larger05; letter-spacing: -0.06em; line-height: 1; display: flex; justify-content: flex-start; align-self: flex-start; position: relative;
			SPAN { font-size: $font-size-smaller; letter-spacing: 0.01em; font-weight: $extra-bold; }
		}
		&-currency { font-size: $font-size-larger02; }
		IMG { margin-right: $gridunit-small; }
	}
}

// Product actions

.product-actions {
	margin: $gridunit-small 0 0;
	.buttons.main {
		> LI {
			flex: 1; max-width: 350px;
			@include mobile { max-width: 100%; }
		}
		@include mobile { margin-right: $gridunit-small; }
		.epharma-dialog-content & {
			@include mobile { margin-right: -$gridunit-small; }
		}
	}
	.button {
		width: 100%; justify-content: center;
		&.button-discrete {
			text-decoration: none; color: $brand-primary-800; font-size: $font-size-medium-large;
			SPAN {
				display: flex; justify-content: center; align-items: center;
				&.icon { width: 36px; height: 36px; border-radius: 50%; background-color: $brand-gray-100; }
			}
		}
	}
	&__row {
		display: flex; justify-content: space-between; flex-wrap: wrap;
		&.align-left {
			justify-content: flex-start;
			> * { margin-right: $gridunit; }
		}
	}
	&__quantity {
		display: flex; justify-content: flex-start; margin: $gridunit-small 0 0;
		LABEL {
			align-self: center; margin-right: $gridunit;
			+ INPUT { width: 5em; }
		}
		&:has([aria-invalid="true"]) {
			BUTTON, INPUT { border-color: $color-system-danger-800; }
			INPUT { color: $color-system-danger-800; }
		}
		BUTTON {
			cursor: pointer; position: relative; z-index: 1; max-width: 34px; background-color: $brand-gray-300; border: 1px solid $brand-gray-placeholder-light; text-indent: -10000px; padding: $gridunit-small $gridunit; color: $color-text-dark; font-weight: $bold;
			&:first-child {
				border-radius: $base-radius 0 0 $base-radius;
				&:before { content: "-"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
			}
			&:last-child {
				border-radius: 0 $base-radius $base-radius 0;
				&:before { content: "+"; text-indent: 0; position: absolute; left: 0; right: 0; top: 0; bottom: 0; display: flex; justify-content: center; align-items: center; }
			}
			&:hover { background-color: $brand-gray-350; }
			&:active { background-color: $brand-gray-350; border-color: $brand-gray-600; }
			&:focus { background-color: $brand-gray-350; box-shadow: $shadow-focus2; }
		}
		INPUT {
			border: 1px solid $brand-gray-placeholder-light; border-right: none; margin: 0 -1px; flex: 0 0 auto; width: 4.5rem; padding: $gridunit-small $gridunit-small/2; text-align: center;
			&:focus { box-shadow: $shadow-focus2; position: relative; z-index: 2; }
		}
	}
	&__discount {
		display: flex; justify-content: flex-start; margin: $gridunit-small 0 0;
		@include mobile { flex-wrap: wrap; }
		LABEL { padding: $gridunit-small+1; flex: 0 0 auto; font-weight: $medium-bold; }
		INPUT {
			border: 1px solid $brand-gray-placeholder-light; border-radius: $base-radius; margin: 0; flex: 0 0 auto; width: 260px; padding: $gridunit-small $gridunit;
			&:focus { box-shadow: $shadow-focus2; position: relative; z-index: 2; }
		}
	}
}

// Product campaign

.frame-epharma .product-campaign {
	background: $brand-campaign-3-tint; border: 2px solid $brand-campaign-3; border-radius: $base-radius; padding: $gridunit-small; margin: $gridunit*2 0 0;
	@include mobile {
		flex-direction: column; overflow: hidden;
	}
	&.theme02 { border-color: $brand-campaign-2; background: $brand-campaign-2-tint; }
	&.theme03 { border-color: $brand-campaign-4; background: $brand-campaign-4-tint;}
	&.theme04 { border-color: $brand-campaign-1; background: $brand-campaign-1-tint;}
	&.theme05 { border-color: $brand-campaign-5; background: $brand-campaign-5-tint;}
	&.info { border-color: $color-system-info-250; background: $color-system-info-200;}
	&.warn { border-color: $color-system-warn-500; background: $color-system-warn-200;}
	&.danger { border-color: $color-system-danger-300; background: $color-system-danger-200;}
	&.success { border-color: $color-system-success-250; background: $color-system-success-200;}
	&.discrete {
		border-color: $brand-gray-350; background: $brand-gray-100;
	}
	&,
	&.theme02,
	&.theme03,
	&.theme04,
	&.theme05,
	&.info,
	&.warn,
	&.danger,
	&.success,
	&.discrete {
		A { color: darken($brand-primary-800-wcag, 5%); }
	}
	&__image {
		svg {
			path { fill: $color-text-dark; }
		}
	}
	&__inner {
		@include mobile {
			.button .button-inner .text { white-space: normal; }
		}
	}
	&__text {
		H2 { text-transform: uppercase; font-size: $base-font-size; font-weight: $bold; }
		P:not(:first-child) { margin: .5em 0 0; }
		A:focus { box-shadow: $shadow-focus; }
	}
	&__content {
		A:focus { box-shadow: $shadow-focus; }
	}
	&__action {
		@include mobile {
			margin-top: $gridunit;
		}
		.button svg {
			path {
				fill: $brand-primary-800; stroke: $brand-primary-800;
			}
		}
	}
}

// Product parameters

.product-params {
	H2 { font-size: $base-font-size; font-weight: $bold; }
	DL {
		@include clearfix;
		font-size: $base-font-size; margin: $gridunit-small 0 0; line-height: 1.5;
		DT, DD { display: block; margin: 0;  }
		DD { font-weight: $bold;}
		@include not-mobile {
			DT { clear: both; float: left; width: 150px; margin: $gridunit-small 0 0; }
			DD { float: left; width: 100%; margin: $gridunit-small 0 0 150px*-1; padding-left: 150+$gridunit; box-sizing: border-box; font-weight: $bold; }
		}
		@include mobile {
			DT { margin: $gridunit-small 0 0; }
		}
	}
	P.has-icon {
		position: relative; padding-left: 24px;
		.ico { position: absolute; left: 0; top: 0; max-width: 18px; }
	}
	&.wide {
		DL {
			@include not-mobile {
				DT { width: 250px; }
				DD { margin: $gridunit-small 0 0 250px*-1; padding-left: 250+$gridunit; }
			}
		}
	}
}

// Product warning

.product-warning {
	position: relative; border-top: 1px solid $brand-gray-350; padding: $gridunit 0 0 50px; line-height: 1.4;
	&__icon { position: absolute; left: 0; top: $gridunit; }
	H2 { margin: 0; font-size: $base-font-size; font-weight: $bold; }
}

// Product warning

.product-attributes {
	border-top: 1px solid $brand-gray-350; line-height: 1.4; padding: 0; margin: $gridunit-large 0 0;
	DIV {
		position: relative;  padding: $gridunit 0 0 50px;
	}
	IMG { position: absolute; left: 0; top: $gridunit; width: 25px; height: auto; }
	DT { margin: 0; padding: 0; font-size: $base-font-size; font-weight: $bold; }
	DD { margin: 1em 0 0; padding: 0; }
}

// Product availability

.product-availability {
	margin: 0; padding: 0;
	> LI {
		margin: $gridunit 0 0; padding: 0;
		&:first-child { margin: 0; }
	}
	H4, H5 {
		margin: 0; font-size: $base-font-size; font-weight: $normal; color: $brand-primary-800-wcag; text-transform: uppercase;
		+ P { margin: 0; }
	}
}

// Product rating

.product-reviews {
	H3 { font-size: $font-size-larger !important; margin: 0 !important; }
	&__item {
		margin: $gridunit 0 0; border-bottom: 1px solid $brand-gray-350; padding-bottom: $gridunit;
		H4 { margin: 0; }
	}
	&__score {
		display: flex; align-items: center; margin: $gridunit-small 0 0;
		SPAN { flex: 0 0 88px; height: 15px; overflow: hidden; margin-right: $gridunit-small; }
		IMG { position: relative; }
		&-1 IMG { top: -15px; }
		&-2 IMG { top: -15px*2; }
		&-3 IMG { top: -15px*3; }
		&-4 IMG { top: -15px*4; }
		&-5 IMG { top: -15px*5; }
	}
	&__comment { display: flex; margin: 0 0 0; }
	&__author {
		flex: 0 0 130px;
		P {
			margin: 0; font-weight: $bold;
			&.product-reviews__meta { font-weight: $normal; font-size: $font-size-small;}
		}
	}
	&__text {
		flex: 0 1 100%;
		P { margin: 0; }
	}
	&__form {
		margin: $gridunit 0 0;
		FIELDSET {
			margin: $gridunit 0 0; padding: 0; border: none;
			.radio-group { display: flex; flex-wrap: wrap; }
		}
		LEGEND { flex: 0 0 auto; margin-right: $gridunit; display: block; }
	}
}

// Product gallery

HTML .gallery {
	&-overlay {
		&__close {
			width: 90px; height: 90px; border-radius: 50%; background: $color-white; opacity: 1; box-shadow: 0px 0px 8px rgba(77, 77, 77, 0.4);
			svg {
				width: 30px; height: 30px;
				path { fill: $brand-primary-800-wcag; }
			}
			&:hover,
			&:active {
				svg path { fill: $brand-primary-900; }
			}
			&:focus { box-shadow: $shadow-focus; }
			@include not-desktop {
				width: 45px; height: 45px;
				svg { width: 20px; height: 20px; }
			}
		}
		&__content {
			.carousel {
				@include desktop {
					.control-dots { display: none; }
					&:not(:first-child) .control-arrow { display: none; }
				}
				@include not-desktop {
					&:not(:first-child) { display: none; }
					.control-dots .dot {
						opacity: 1; background: $color-white; border: 1px solid $brand-gray-placeholder; width: 12px; height: 12px; box-sizing: border-box;
						&.selected { background: $brand-primary-800; border-color: $brand-primary-800; }
						&:hover { background-color: $brand-gray-placeholder; border-color: $brand-gray-placeholder; }
						&:active { background-color: $brand-primary-900; border-color: $brand-primary-900; }
						&:focus { box-shadow: $shadow-focus2; }
					}
				}
				.control-arrow {
					background: $color-white; border: 1px solid $brand-primary-800;
					svg {
						display: none;
					}
					&:before { display: block; content: ""; width: 12px; height: 12px; border: 2px $brand-primary-800; border-style: solid solid none none; }
					&.control-prev:before { transform: rotate(225deg); margin-left: 6px; }
					&.control-next:before { transform: rotate(45deg); }
					&:hover {
						background-color: $brand-primary-800;
						&:before { border-color: $color-text-light; }
					}
					&:active {
						background-color: $brand-primary-900;
						&:before { border-color: $color-text-light; }
					}
					&:focus {
						box-shadow: $shadow-focus2;
					}
				}
				.slide { background-color: $color-white; text-align: center; }
				IMG { margin: 0 auto; max-height: 100%; max-width: 100%; height: auto; width: auto; object-fit: contain; object-position: center; }
			}
		}
	}
}

.carousel {
	.thumb {
		border-radius: $base-radius; border: 1px solid transparent;
		&.selected,
		&:hover { border: 1px solid $brand-gray-600; }
		&:focus { border: 1px solid $color-system-focus; }
	}
	&-root {
		&:focus { box-shadow: $shadow-focus; }
	}
}

// Products scroller

.products-carousel {
	&:hover {
		.carousel-inline.carousel .control-arrow { opacity: 1; }
	}
	.carousel-inline {
		&.size-auto .slide {
			@include desktop { flex: 0 0 33.3333%; }
			@include desktop-wide { flex: 0 0 25%; }
			@include tablet { flex: 0 0 50%; }
			@include mobile { flex: 0 0 100%; }
			.box-product { max-width: none; };
		}
		&.carousel .control-arrow {
			opacity: 0; transition: opacity $uispeed $uieasing;
			&:focus { opacity: 1; }
			&:before { border-color: $brand-primary-800; }
			svg path { fill: $brand-primary-800; }
			@include mobile {
				opacity: 1;
			}
		}
	}
	@include mobile {
		margin: 0 $gridunit-small*-1;
	}
}

.product-filters {
	margin: $gridunit 0 0;
	&__form {
		display: flex; flex-direction: column; padding: $gridunit/2; border: 1px solid $brand-gray-300; border-radius: $base-radius $base-radius 0 0;
		&-row {
			display: flex; overflow: hidden;
			&.wrap {
				flex-wrap: wrap;
				.product-filters__form-item.secondary { opacity: 1; }
			}
		}
		&-item {
			margin: $gridunit/2; display: flex;
			&.secondary {
				opacity: 0;
			}
			.toggler {
				border-radius: $base-radius;
				&:focus { background-color: $color-system-info-200; }
			}
			.checkboxes {
				max-height: 30vh; overflow: auto; padding-bottom: 1em; padding-left: 3px;
				&:not(:first-child) { margin-top: $gridunit-small; }
				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-track {
					background: $brand-gray-200;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $brand-accent-100; border-radius: $base-radius;
				}
			}
			.treeview {
				max-height: 30vh; overflow: auto; padding-bottom: 1em; margin-top: 3px;
				&::-webkit-scrollbar {
					width: 5px;
				}

				&::-webkit-scrollbar-track {
					background: $brand-gray-200;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $brand-accent-100; border-radius: $base-radius;
				}
			}
		}
		FIELDSET { border: none; padding: 0; margin: 0; }
		LEGEND {
			display: flex; position: relative; padding: $gridunit-small $gridunit-large $gridunit-small $gridunit-small; cursor: pointer; white-space: nowrap; flex-wrap: nowrap;
			&:hover { color: $brand-primary-800-wcag; }
			&:focus { box-shadow: $shadow-focus2; }
			.toggle-icon {
				position: absolute; right: 0; top: .7em;
				height: 7px; margin-left: $gridunit-small;
				IMG { display: block; }
			}
			.toggle-text { flex: 0 1 100%; }
			+ * { display: none; }
		}
		.buttons { margin: $gridunit-small/2 0 0 $gridunit-small; }
		@include mobile {
			padding: $gridunit/2 0;
			&-item { margin: $gridunit-small $gridunit-small $gridunit-small 0; }
			.buttons { flex: 0 0 100%; margin: 0; }
		}
	}
	&__applied {
		border: 1px $brand-gray-300; border-style: none solid solid; background: $brand-gray-200; padding: $gridunit; display: flex; align-items: flex-start; justify-content: space-between; border-radius: 0 0 $base-radius $base-radius;
		P { margin: 0; font-weight: $bold; display: flex; flex-wrap: wrap; flex: 0 1 100%; align-items: baseline; }
		.button { background-color: $color-white; flex: 0 0 auto; }
		@include mobile {
			display: block; padding: $gridunit-small;
			&-title { flex: 0 0 100%; }
			.button { margin: $gridunit-small/2 0 0; }
		}
	}
	&__results {
		display: flex; justify-content: space-between; margin: $gridunit 0 0;
		P { margin: $gridunit-small 0 0; }
		FIELDSET { border: none; padding: 0; margin: 0; }
		LEGEND {
			display: block; padding: $gridunit-small; cursor: pointer;
			&:hover { color: $brand-primary-800-wcag; }
			&:focus { box-shadow: $shadow-focus2; }
			.toggle-icon {
				height: 7px; float: right; margin-left: $gridunit-small; margin-top: 6px;
				IMG { display: block; }
			}
		}
	}
	&__tag {
		background: $brand-accent-100; padding: $gridunit-small/2 $gridunit-small; margin: $gridunit-small/2; border-radius: $base-radius; font-weight: normal;
		BUTTON {
			background: none; color: $color-text-dark; border-radius: 50%; text-indent: -10000px; overflow: hidden; border: none; width: 24px; height: 24px; position: relative; cursor: pointer;
			.icon {
				position: absolute; width: 16px; height: 16px; display: block; overflow: hidden; left: 50%; top: 50%; transform: translate(-50%, -50%);
				IMG { width: 16px; height: 16px; object-fit: cover; object-position: top center; position: absolute; left: 0; top: 0; }
			}
			&:focus { box-shadow: $shadow-focus; }
			&:hover .icon {
				IMG {  object-position: bottom center;}
			}
		}
	}
	& &__toggle-all {
		margin: 0 0 0 $gridunit-small;
		@include not-mobile { display: none; }
	}
	&__view {
		display: flex; flex: 1; justify-content: flex-end; margin: $gridunit-small 0 0 $gridunit;
		.button {
			min-height: 1.7em; text-align: center;
			IMG,
			svg { margin-right: 0 !important; }
			&.intent-primary svg path { fill: $color-text-light; }
			&:not(:last-child) { margin-right: $gridunit-small/2; }
		}
		.button-icon {
			margin: 0;
			&:not(:last-child) { margin-right: 3px; }
		}
		@include mobile { display: none; }
	}
}

.product-filter-drop {
	position: relative; background-color: $color-white; box-shadow: $shadow04; padding: $gridunit; border-radius: $base-radius; z-index: 2; min-width: 300px; box-sizing: border-box;
	.buttons {
		margin-left: 0;
		LI {
			flex: 0 0 100%; margin: 0;
			.button { width: 100%; }
		}
	}
	.bp5-popover-content { font-size: inherit !important; }
}

// Product compare

.product-compare {
	display: flex; justify-content: center; margin: $gridunit $gridunit-small 0; align-items: center;
	.button { margin: 0 $gridunit-small/2; flex: 0 0 auto; }
	@include mobile { display: none; }
	&__item,
	&__placeholder { flex: 0 1 270px; margin: 0 $gridunit-small/2; border: 1px solid $brand-gray-300; border-radius: $base-radius; padding: $gridunit-small; display: flex; align-items: flex-start; }
	&__item {
		A {
			display: flex; text-decoration: none; text-transform: uppercase; color: $color-text-dark; align-items: center; line-height: 1.3em;
			SPAN { max-height: 2.6em; overflow: hidden; }
			IMG {
				border-radius: $base-radius; overflow: hidden; margin: 0 $gridunit-small 0 0; flex: 0 0 $gridunit-large*2; max-width: 48px; max-height: 48px;
				@include tablet { display: none; }
			}
			&:focus { text-decoration: underline; }
		}
		.button-icon {
			margin: 0;
			&.size-small svg { width: 12px; height: 12px; }
			&:hover {
				.button-icon-main:before { opacity: 0; }
				svg path { fill: $color-system-danger-800; }
			}
		}
	}
	&__placeholder {
		align-items: center; font-size: $font-size-small; align-self: stretch;
		IMG {
			border-radius: $base-radius; overflow: hidden; margin: 0 $gridunit-small 0 0; flex: 0 0 $gridunit-large*2;
			@include tablet { display: none; }
		}
	}
	&__remove {
		cursor: pointer; border: none; background: none; width: $gridunit-large; height: $gridunit-large; display: flex; overflow: hidden; flex: 0 0 $gridunit-large; padding: 0; margin: 0 0 0 $gridunit-small;
		IMG { width: $gridunit-large; height: $gridunit-large*2; }
		&:hover,
		&:active { align-items: flex-end; }
		&:focus { box-shadow: $shadow-focus; }

	}
	&-grid {
		table-layout: fixed; line-height: 1.3; width: 100%; border-collapse: collapse;
		@include mobile { display: none; }
		TD, TH { vertical-align: top; padding: $gridunit $gridunit-small; word-wrap: break-word; }
		TR:not(.noborder) {
			TD,
			TH {
				&:first-child { border-left: none; }
				&:last-child { border-right: none; }
				border: 1px solid $brand-gray-300;
			}
			&:last-child {
				TD,
				TH { border-bottom: none; }
			}
		}
		TH { width: 10%; font-weight: $bold; }
		TD {
			width: 30%; height: 1px;
			> *:first-child { margin-top: 0; }
		}
		.box-product {
			border: 1px solid $brand-gray-300; margin: $gridunit auto 0; height: 100%; flex: 0 1 100%; max-width: 100%; box-sizing: border-box;
			&.compact { border: none; margin: 0; padding: 0; }
			@-moz-document url-prefix() {
				height: auto;
			}
		}
		.layout-products__container {
			max-width: 100%; min-width: 0; width: 276px; height: 100%; margin: 0 auto; display: flex; flex-direction: column; align-items: flex-start;
			@-moz-document url-prefix() {
				height: auto;
			}
		}
		&__remove.button {
			margin: 0 !important;
		}
	}
}

.product-upsell {
	&__total {
		font-size: $font-size-larger; margin: $gridunit 0 0;
		P { margin: 0; }
		@include not-mobile {
			display: flex; justify-content: space-between; align-items: center;
			.button { margin: 0 $gridunit-small; }
		}
	}
}

.product-in-cart {
	position: relative; margin-right: $gridunit-small*1.5; font-size: $base-font-size;
	IMG { display: block; }
	SPAN { width: 1.5em; height: 1.5em; position: absolute; left: 50%; top: -.5em; background-color: $brand-primary-800; color: $color-text-light; font-size: $font-size-smallest; display: flex; justify-content: center; align-items: center; border-radius: 50%; }
}
