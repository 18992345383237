// Grid layout for promotions

.layout-grid {
  line-height: 1.4;
  @include mobile {
    margin: 0;
    &__container { padding: $gridunit 0 0 0; }
    &__columns { display: flex; flex-direction: column; align-items: stretch; }
    &__column {
      + .layout-grid__column { margin-top: $gridunit; }
    }
  }
  @include not-mobile {
    display: flex; flex-direction: row; flex-wrap: wrap; padding: 0 0 0 $gridunit; margin: 0 $gridunit*-1 0 $gridunit*-1; justify-content: space-between;
    &__container {
      flex: 0 0 50%; max-width: 650px; box-sizing: border-box; padding: $gridunit $gridunit 0 0; display: flex; flex-direction: column;
      &.wide { flex: 0 0 100%; max-width: 100%; }
      > *:first-child { margin: 0; }
    }
    &__columns {
      display: flex; gap: $gridunit;
      &.reverse { flex-direction: row-reverse; }
      &.align-v-center { align-items: center; }
      &.align-v-bottom { align-items: flex-end; }
    }
    &__column {
      flex: 1;
      > *:first-child { margin-top: 0; }
      @include desktop {
        &.size01 { flex: 0 1 66.666%; }
        &.size02 { flex: 0 1 33.333%; }
      }
      .visible-focus & A:not([class]):focus { box-shadow: $shadow-focus; }
    }
  }
  @include defaultMargin($gridunit);
}
