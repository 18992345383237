$ns: 'bp4';
// Units

$gridunit: 16px;
$gridunit-small: $gridunit/2;
$gridunit-medium: $gridunit-small*1.5;
$gridunit-large: $gridunit-small*3;
$uispeed: 250ms;
$uieasing: cubic-bezier(0.42, 0, 0.58, 1);
$base-radius: 3px;
$base-radius-medium: 5px;
$base-radius-large: 19px;
$content-maxwidth: 1400px;

$grid-gap: $gridunit;
$column-width: 78px;

$spacer-desktop: $grid-gap*1.5;
$spacer-desktop-vertical: $grid-gap*1.5;
$spacer-tablet: $grid-gap;
$spacer-tablet-vertical: $grid-gap;
$spacer-mobile: $grid-gap;
$spacer-mobile-vertical: $grid-gap;

// Colors
$color01: #031b30;
$color02: #0071e3;
$color03: #e1eaef;
$color04: #f3f6fa;
$color05: #ff0053;
$color06: #0077ED;
$color07: #2997ff; // Link on black
$color08: #e85d00; // Highlight
$color09: #68cc45;
$color10: #febe30;

$color-white: #fff;
$color-black: #4F4F50; // Todo: This matches the brand gray dark 800
$color-disabled: rgba($color-black, 0.5);
$color-text-medium: #767676;
$color-text-dark: $color-black;
$color-text-light: $color-white;

$color-gray-lightest: #fafafa;
$color-gray-lighter: #f2f2f2;
$color-gray-light: #eee; // edited from figma, language hover
$color-gray-medium-light: #bcbcbc; // added, from figma, language active
$color-gray-medium-light02: #A1A1A1;
$color-gray-medium: #949494;
$color-gray-medium-dark: #8b8b8b; // Todo: where else is this used
$color-gray-dark: #4F4F50;
$color-gray-darker: #474747;
$color-gray-darkest: #222;

$brand-primary-200: #93ebed;
$brand-primary-600: #2cd5d9;
$brand-primary-800: #24aeb1;
$brand-primary-800-wcag: #007d80;
$brand-primary-900: #005c5e;
$brand-primary-text: $color-text-light;

$brand-accent-wcag: $brand-primary-800-wcag;
$brand-accent-100: #fdf1b9;
$brand-accent-200: #fde679;
$brand-accent-1: #fdd204;
$brand-accent-2: #2cd5d9;
$brand-accent-2-200: #bae8e8;
$brand-accent-2-100: #e6fffe;

$brand-gray-100: $color-gray-lightest;
$brand-gray-200: $color-gray-lighter;
$brand-gray-300: $color-gray-light;
$brand-gray-350: #E1E1E1;
$brand-gray-400: #BCBCBC;
$brand-gray-600: #A1A1A1;
$brand-gray-800: $color-gray-dark;
$brand-gray-900: #3F3F3F;
$brand-gray-placeholder: #767676;
$brand-gray-placeholder-light: #949494;

$brand-campaign-1: #fdd204;
$brand-campaign-1-tint: #fdf6d7;
$brand-campaign-2: #8bc47f;
$brand-campaign-2-tint: #e6f2e4;
$brand-campaign-3: #2cd5d9;
$brand-campaign-3-tint: #e6feff;
$brand-campaign-4: #0099bd;
$brand-campaign-4-tint: #e6feff;
$brand-campaign-5: #fdb604;
$brand-campaign-5-tint: #fdf6d7;


$brand-badge01-background: $brand-primary-800-wcag;
$brand-badge01-text: $brand-primary-text;
$brand-badge02-background: $brand-gray-200;
$brand-badge02-text: $color-text-dark;
$brand-badge03-background: $brand-accent-1;
$brand-badge03-text: $color-text-dark;
$brand-badge04-background: $brand-primary-800-wcag;
$brand-badge04-text: $brand-primary-text;

$brand-focus-1: #5469d4;

$color-system-success-200: #E5EFE5;
$color-system-success-250: #B9D8B9;
$color-system-success-800: #006400;
$color-system-warn-200: #FFF3C8;
$color-system-warn-500: #FFDF6E;
$color-system-warn-800: #EFC83D;
$color-system-warn-1000: #febe30;
$color-system-warn-650: #DF8600;
$color-system-danger-200: #FFF1F0;
$color-system-danger-300: #F3C3C0;
$color-system-danger-800: #D1191B;
$color-system-info-1000: #0B7495;
$color-system-info-200: #E5F2F6;
$color-system-info-250: darken($color-system-info-200, 5%);
$color-system-info-350: #C6DBE1;
$color-system-info-500: #15A0BC;

$color-system-focus: #0F7798;
$color-link: $brand-primary-900;
$color-link-hover: $color06;

$color-primary: $color02;
$color-success: $color09;
$color-warning: $color10;
$color-danger: $color05;
$color-disabled: $color-gray-medium;
$color-active: $color-link;

$color-background-header-primary: $color-white;
$color-background-header-secondary: $color-background-header-primary;
$color-border-header: $color-gray-lighter;

$color-background-languages-idle: $color-white;
$color-background-languages-hover: $color-gray-light;
$color-background-languages-active: $color-gray-medium-light;

$color-background-crossportal-hover: transparentize($color-gray-medium-dark, 0.8);
$color-background-crossportal-active: transparentize($color-gray-medium-dark, 0.6);

$color-accent-product: #EAF7FF;

$color-text-header-primary: $color-text-dark;
$color-text-header-secondary: $color-text-medium;
$color-text-header-highlighted: $brand-primary-800;
$color-header-accent: $brand-accent-wcag;

$color-background-mainmenu-level1-hover: #E6F3FB;
$color-background-mainmenu-level1-active: #E6F3FB;
$color-border-mainmenu-level1-active: $brand-primary-800;
$color-background-mainmenu-level1-selected: $brand-primary-800;
$color-background-mainmenu-level1-selected-hover: $brand-primary-900;
$color-background-mainmenu-level1-selected-active: #BD2325;
$color-text-mainmenu-level1-selected: $color-text-light;

$color-background-mainmenu-level2-hover: #E6F3FB;
$color-background-mainmenu-level2-active: #E6F3FB;
$color-border-mainmenu-level2-active: $brand-primary-800;
$color-background-mainmenu-level2-selected: $brand-primary-800;
$color-background-mainmenu-level2-selected-hover: $brand-primary-900;
$color-background-mainmenu-level2-selected-active: #BD2325;
$color-text-mainmenu-level2-selected: $color-text-light;

// Sizes

$logo-height: 52px;
$logo-width: 237px;

$mobile-header-height: 50px;
$comment-width: 280px;

$input-height: 40px;
$input-background: $color-white;
$input-background-focus: $color-white;
$input-border-color: rgba($color-black, 0.2);
$input-border-color-hover: rgba($color-black, 0.25);
$input-border-color-focus: rgba($color-black, 0.30);

$button-default-height: $input-height;
$button-small-height: $gridunit;

$base-padding: 0 $gridunit $gridunit $gridunit;
$base-padding-large: 0 $gridunit-large $gridunit-large $gridunit-large;

$base-padding-mobile: 0 $spacer-mobile $spacer-mobile-vertical $spacer-mobile;
$base-padding-tablet: 0 $spacer-tablet $spacer-tablet-vertical $spacer-tablet;
$base-padding-desktop: 0 $spacer-desktop $spacer-desktop-vertical $spacer-desktop;

$base-margin: $gridunit 0 0 0;
$base-margin-large: $gridunit-large 0 0 0;

$base-font-size: 16px;
$font-size-smallest: 10px;
$font-size-smaller: 12px;
$font-size-small: 13px;
$font-size-medium-small: 14px;
$font-size-medium: 16px;
$font-size-medium-large: 18px;
$font-size-large: 20px;
$font-size-larger: 24px;
$font-size-larger02: 32px;
$font-size-larger03: 28px;
$font-size-larger04: 30px;
$font-size-larger05: 26px;
$font-size-largest: 34px;

$base-line-height: 1.375;
$line-height-small: 16px;
$line-height-medium: 26px;
$line-height-large: 30px;

$shadow01: 0 8px 32px 0 rgba($color-black, 0.1);

$shadow02: 0 $gridunit / 2 $gridunit $gridunit / -2 $color-black;
$shadow03: 0 $gridunit / 2 $gridunit / 2 $gridunit / -2 rgba($color-black, 0.2);
$shadow04: 0px 8px 36px rgba(77, 77, 77, 0.16);
$shadow-menus: 0 2px 4px 0 #00000033, 0 1px 10px 0 #0000001F, 0 4px 5px 0 #00000024;
$shadow-menus02: 0px 12px 80px rgba(0, 0, 0, 0.07), 0px 5.01331px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 2.68036px 17.869px rgba(0, 0, 0, 0.0417275), 0px 1.50259px 10.0172px rgba(0, 0, 0, 0.035), 0px 0.798012px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.332071px 2.21381px rgba(0, 0, 0, 0.0196802);
$shadow-menus03: 0px 0px 4px rgba(77, 77, 77, 0.2);

$shadow-focus: 0 0 0 2px $color-system-focus;
$shadow-focus-inverted: 0 0 0 2px $color-system-info-350;
$shadow-focus-inverted-inset: inset 0 0 0 2px $color-system-info-350;
$shadow-focus-inset: inset 0 0 0 2px $color-system-focus;
$shadow-focus2: 0 0 0 2px $color-white, 0 0 0 4px $color-system-focus;
$shadow-active: 0 0 0 1px $color-border-mainmenu-level1-active;
$shadow-active2: 0 0 0 1px $color-border-mainmenu-level2-active;

$light: 300;
$normal: 400;
$medium: 500;
$medium-bold: 600;
$bold: 700;
$extra-bold: 800;

$intents: (
		"primary": $color-link,
		"success": $color-success,
		"warning": $color-warning,
		"danger": $color-danger,
		"active": $color-active,
);
