// Single product layout

.layout-product {
  @include desktop {
    &__row { display: flex; flex-direction: row; flex-wrap: nowrap; }
    &__title {
      margin: $gridunit-large 0 0;
      + .layout-product__row { margin-top: $gridunit-large; }
    }
    &__image { flex: 1 1 50%; margin-right: 80px; font-size: 12px; line-height: 1.5; align-self: flex-start; }
    &__content { flex: 1 1 50%; }
    &__header {
      H1 { display: inline; }
      .product-rating { display: inline-block; margin: 0 $gridunit-small; }
      .button { top: -0.35em; }
    }
    &__meta { flex: 0 1 50%; padding-right: 80px; box-sizing: border-box; }
    &__tabs {
      flex: 0 1 50%; max-width: 50%;
      &:first-child:last-child { flex: 0 0 100%; max-width: none; }
    }
  }
  &__content {
    .visible-focus & A:not([class]):focus { box-shadow: $shadow-focus;}
  }

  &__meta02 {
    display: flex; flex-wrap: wrap; margin: $gridunit 0 0; color: $brand-gray-800;
    > LI { margin-right: $gridunit; margin-top: $gridunit-small; padding-right: $gridunit; border-right: 1px solid $brand-gray-350; }
  }

  @include desktop-narrow {
    &__row.wrap-narrow { flex-direction: column; }
    &__image { align-self: stretch; }
  }

  H1 {
    font-size: 34px; margin: 0; font-weight: $bold;
    .button { margin-left: $gridunit-small; }
    @include mobile {
      font-size: $font-size-large;
      .button { position: relative; top: .15em !important; }
      .product-rating { padding: 0 3px; top: 0; }
    }
  }

  &__image {
    position: relative;
    .special-label {
      pointer-events: none; position: absolute; text-align: center; max-width: calc(100% - 60px); word-wrap: break-word; font-size: $font-size-small; font-weight: $bold; left: $gridunit-small; z-index: 2; background-color: $color-system-info-250; border-radius: 2em; padding: $gridunit-small/2 $gridunit;
      @include desktop {
        bottom: 100px;
      }
      @include desktop-narrow {
        bottom: $gridunit;
      }
      @include not-desktop {
        bottom: $gridunit;
      }
    }
  }

  @include not-desktop {
    &__header {
      H1 { display: block; }
      .button,
      .product-rating { top: 0; margin-top: .3em; }
      .product-rating { top: -.1em; display: inline-block; }
      .button { margin-left: $gridunit-small/2; }
    }
  }
}
