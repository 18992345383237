.frame-epharma .layout-sidebar {
  &__primary {
    IMG { max-width: 100%; height: auto; }
  }
  .visible-focus & A:not([class]):focus { box-shadow: $shadow-focus; }
  @include not-mobile {
    display: flex;
    &__primary {
      flex: 1 1 auto; max-width: calc(100% - 340px - #{$gridunit*4});
    }
    &__secondary {
      flex: 0 0 340px; margin-left: $gridunit*4;
      H2 {
        font-weight: $bold;
        &:first-child { margin-top: $gridunit-small; }
      }
    }
  }
}


